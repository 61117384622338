import { Component } from 'react'
import { createPortal } from 'react-dom'

class Portal extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return createPortal(this.props.children, this.props.rootNode)
  }
}

export default Portal