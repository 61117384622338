import '~/styles/Demo.scss'
import { useRef, useState, useEffect } from 'react'
import useVideoStore from '~/store/video'
import useUiStore from '~/store/ui'
import useBsStore from '~/store/bs'
import useDemoEvents from '~/hooks/useDemoEvents'

const BLACKOUT_MS = 500

const Demo = () => {
  const vidRef = useRef()

  const { setKey: setVidSrcKey, vidRef: bgVid } = useVideoStore(state => state)
  const { setAdaContent, setTransDest, setHideUi, openLoader, teardownLoader } = useUiStore(state => state)
  const { isBrightSign } = useBsStore(state => state)
  const [viewing, setViewing] = useState(false)
  const { bindVideoAnalytics, finVideoAnalytics, cancelVideoAnalytics } = useDemoEvents()
  const vlib = useVideoStore(state => state.lib)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    setAdaContent([{el:'h1',key:'branding.tag'}])
    initVideo()
    setHideUi(true)
    bindVideoAnalytics(vidRef.current, "Hero")

    return(() => {
      setHideUi(false)
      cancelVideoAnalytics(vidRef.current)
      if (vidRef.current) { // Destroyed if using local <video/>
        vidRef.current.oncanplaythrough = null
        vidRef.current.oncanplay = null
        vidRef.current.onended = null
        vidRef.current.onwaiting = null
        vidRef.current.onplaying = null
      }
    })
  }, [])

  function initVideo() {
    //// setVidSrcKey("demo")
    // if (isBrightSign) {
      vidRef.current.oncanplaythrough = onCanPlay
    // } else {
      openLoader()
      // vidRef.current.oncanplay = onCanPlay
      vidRef.current.onwaiting = onWaiting
      vidRef.current.onplaying = onPlaying
    // }
    vidRef.current.onended = onEnded
  }

  function onPlay(e) {
    // teardownLoader()
    setViewing(true)
  }

  function onPlaying(e) {
    // teardownLoader()
  }

  function onWaiting(e) {
    // openLoader()
  }

  function onCanPlay(e) {
    vidRef.current.onplay = onPlay
    teardownLoader()
    setReady(true)
    // e.target.play()
  }

  function onEnded(e) {
    setViewing(false)
    setTransDest('/features')
  }

  function close() {
    vidRef.current.pause()
    finVideoAnalytics(vidRef.current)
    cancelVideoAnalytics(vidRef.current)
    setTransDest('/features')
  }

  return(
    <div id="Demo">
      <div class="vid-cnr" style={{ opacity: ready ? 1 : 0 }}>
        <video
          ref={vidRef}
          src={vlib["Demo"]}
          controls={true}
          muted={false}
          playsInline={true}
        >
        </video>
      </div>
      <span className="video-close" onClick={e => close()}>
        <svg width="100" height="100" viewBox="0 0 100 100">
          <line x1="3" y1="3" x2="97" y2="97" stroke="white" strokeWidth="6" />
          <line x1="3" y1="97" x2="97" y2="3" stroke="white" strokeWidth="6" />
        </svg>
      </span>
    </div>
  )
}

export default Demo