import { useEffect, useState } from 'react'

const ImgLazy = (props) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const img = document.createElement('img')
    img.addEventListener('load', onLoad)
    img.src = props.src
    return () => {
      img.removeEventListener('load', onLoad)
    }
  }, [])

  function onLoad(e) {
    setLoaded(true)
    props.onLoad()
  }

  return(
    <img
      className="ImgLazy"
      style={{
        opacity: loaded && props.visible ? 1 : 0,
        transform: `scale(${loaded && props.visible ? 1 : 0.5})`,
        transition: `opacity 0.33s ease, transform 0.33s ease`,
        ...props.style
      }}
      alt={props.alt}
      src={props.src}
    />
  )
}

export default ImgLazy