import '~/styles/VideoLayer.scss'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import useVideoStore from '~/store/video'
import useUiStore from '~/store/ui'
import useBsStore from '~/store/bs'
import { forceBsUi } from '~/config'

const resMap = {
  'default': process.env.DIST === 'soho' ? [2160, 3840] : [1080, 1920],
  'XD1034': [1080, 1920],
  'HS144': [800, 1280],
}

const VideoLayer = () => {
  const vRef = useRef()
  const { i18n } = useTranslation()
  const srcKey = useVideoStore(state => state.key)
  const setVidRef = useVideoStore(state => state.setVidRef)
  const loop = useVideoStore(state => state.loop)
  const vlib = useVideoStore(state => state.lib)
  const transDest = useUiStore(state => state.transDest)
  const isBrightSign = useBsStore(state => state.isBrightSign)
  const deviceInfo = useBsStore(state => state.deviceInfo)
  const useBsUi = isBrightSign || forceBsUi
  const srcKeyRef = useRef(srcKey)

  useEffect(() => {
    setVidRef(vRef)
    if (isBrightSign) { initBS() }
  }, [])

  useEffect(() => {
    srcKeyRef.current = srcKey
    const vid = vRef.current
    const src = fetchSrc(srcKey)
    vid.src = src
    vid.load()
    if (!srcKey) { vid.play() }
  
    if (!useBsUi && srcKey?.match('lifestyle')) { vid.muted = false }
    else if (!useBsUi && srcKey?.match('demo')) { vid.muted = false; vid.controls = true }
    else { vid.muted = true; vid.controls = false }
  }, [srcKey])

  useEffect(() => {
    vRef.current.pause()
  }, [transDest])

  function initBS() {
    const vid = vRef.current
    vid.src = fetchSrc('attract')
    vid.load()
    vid.play()
    vid.src = fetchSrc('capture')
    vid.load()
    vid.play()
    vid.src = fetchSrc('share')
    vid.load()
    vid.play()
    vid.src = fetchSrc('listen')
    vid.load()
    vid.play()
    vid.src = fetchSrc('specs')
    vid.load()
    vid.play()
    vid.src = fetchSrc('lifestyle-dog')
    vid.load()
    vid.play()
    vid.src = fetchSrc('lifestyle-friends')
    vid.load()
    vid.play()
    vid.src = fetchSrc('lifestyle-phone')
    vid.load()
    vid.play()
    vid.src = fetchSrc('lifestyle-longboard')
    vid.load()
    vid.play()
    vid.src = fetchSrc('lifestyle-motors')
    vid.load()
    vid.play()
    vid.src = fetchSrc('lifestyle-train')
    vid.load()
    vid.play()
    vid.src = srcKey
    vid.load()
    vid.play()
  }

  function fetchSrc(key) {
    switch (key) {
      case 'attract':
        return vlib['Attract']
      case 'capture':
        return vlib['Capture']
      case 'share':
        return vlib['Share']
      case 'listen':
        return vlib['Listen']
      case 'specs':
        return vlib['Specs']
      case 'demo':
        return vlib['Demo']
      case 'lifestyle-dog':
        return getLifestyleVideo('LifestyleDog')
      case 'lifestyle-friends':
        return getLifestyleVideo('LifestyleFriends')
      case 'lifestyle-phone':
        return getLifestyleVideo('LifestylePhone')
      case 'lifestyle-longboard':
        return getLifestyleVideo('LifestyleLongboard')
      case 'lifestyle-motors':
        return getLifestyleVideo('LifestyleMotors')
      case 'lifestyle-train':
        return getLifestyleVideo('LifestyleTrain')
      default:
        return vlib['Empty']
    }
  }

  function getLifestyleVideo(key) {
    let lng = (i18n.language === 'fr-CA') ? 'FRCA' : 'EN'
    let finKey = `${key}_${lng}`
    return vlib[finKey]
  }

  const activeRes = useBsUi ? resMap[(deviceInfo?.model||'default')] : resMap['default']

  return(
    <div
      id="VideoLayer"
      className={`
        ${isBrightSign ? 'bs' : 'no-bs'}
        ${(srcKey||'').match('lifestyle') ? 'lifestyle' : ''}
      `}>
      <video
        ref={vRef}
        hwz="z-index:-1"
        width={activeRes[0]}
        height={activeRes[1]}
        loop={loop}
        playsInline
        src={vlib['Empty']}
      />
    </div>
  )
}

export default VideoLayer