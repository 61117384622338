import createStore from '~/utils/createStore'
import { attractTimeout, attractTimeoutActive, guidedMode } from '~/config'

let attractInterval = null
let attractRemainingInit = attractTimeout / 1000
let attractRemaining = attractRemainingInit
let loaderTmt = null
let windowDims = ((new URL(document.location)).searchParams.get("windowDims") || `0,0,${window.innerWidth},${window.innerHeight}`).split(',').map(n => parseInt(n))
let defaultAppBounds = { x: windowDims[0], y: windowDims[1], w: windowDims[2], h: windowDims[3] }

const useStore = createStore((set, get) => ({
  adaPanelOpen: false,
  setAdaPanelOpen: (val) => set(() => ({adaPanelOpen: val, languageToggleOpen: false})),

  adaContent: [/* { el: {type}, key: {i18n key} } */],
  setAdaContent: (array) => {
    set({ adaContent: array })
  },

  interactive: true,
  setInteractive(bool) {
    set({ interactive: bool })
  },

  renderAda: false,
  setRenderAda(bool) {
    set({ renderAda: bool })
  },

  adaPortalRef: undefined,
  setAdaPortalRef(ref) {
    set({ adaPortalRef: ref })
  },

  renderLoader: false,
  showLoader: false,
  setRenderLoader: (val) => set(() => ({renderLoader: val})),
  setShowLoader: (val) => set(() => ({showLoader: val})),
  openLoader: () => {
    clearTimeout(loaderTmt)
    set({ showLoader: true, renderLoader: true })
  },
  teardownLoader: () => {
    set({ showLoader: false, renderLoader: false })
    // set({ showLoader: false })
    // loaderTmt = setTimeout(() => {
    //   set({ renderLoader: false })
    // }, 3000);
  },

  hideUi: false,
  setHideUi(hideUi) {
    set({ hideUi })
  },

  highlight: '',

  transRef: null,
  setTransRef(ref) {
    set({ transRef: ref })
  },
  transDest: null,
  setTransDest: (dest) => {
    const { highlight: hl } = get()
    const highlight = dest || hl
    set({ transDest: dest, highlight })
  },

  guidedMode,
  setGuidedMode: (val) => {
    set({ guidedMode: val })
  },

  languageToggleOpen: false,
  setLanguageToggleOpen: (val) => {
    set({ languageToggleOpen: val })
  },

  changingLanguage: false,
  setChangingLanguage: async (val) => {
    set({ changingLanguage: val })
  },

  defaultLanguage: 'en',
  setDefaultLanguage: (to) => {
    set({ defaultLanguage: to })
  },

  frenchDialect: 'fr',
  setFrenchDialect: (to) => {
    let { defaultLanguage } = get()
    set({
      frenchDialect: to,
      defaultLanguage: defaultLanguage.match('fr') ? to : defaultLanguage,
    })
  },

  attractTimeoutDur: attractTimeout,
  attractTimeoutActive: false,
  setAttractTimeoutActive: (bool) => {
    /* Uncomment To Log Countdown: */
    // if (bool) {
    //   attractInterval = setInterval(() => {
    //     attractRemaining -= 1
    //     console.log(attractRemaining)
    //   }, 1000)
    // } else {
    //   clearInterval(attractInterval)
    //   attractRemaining = attractRemainingInit
    // }
    set({ attractTimeoutActive: attractTimeoutActive && bool })
  },
  pulseAttractTimeout: () => {
    if (get().attractTimeoutActive) {
      set({ attractTimeoutActive: false })
      set({ attractTimeoutActive: true })
    }
  },

  showBSOverlay: false,
  setShowBSOverlay: (bool) => {
    set({ showBSOverlay: bool })
  },

  appBounds: defaultAppBounds,
  setAppBounds: (bounds) => {
    set({ appBounds: bounds })
  },
}))

export default useStore