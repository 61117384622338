import Empty from 'VIDEO_Empty'
import Attract from 'VIDEO_Attract'
import Capture from 'VIDEO_Capture'
import Share from 'VIDEO_Share'
import Listen from 'VIDEO_Listen'
import Specs from 'VIDEO_Specs'
import Demo from 'VIDEO_Demo'
import LifestyleDog_EN from 'VIDEO_LifestyleDog_EN'
import LifestyleFriends_EN from 'VIDEO_LifestyleFriends_EN'
import LifestylePhone_EN from 'VIDEO_LifestylePhone_EN'
import LifestyleLongboard_EN from 'VIDEO_LifestyleLongboard_EN'
import LifestyleMotors_EN from 'VIDEO_LifestyleMotors_EN'
import LifestyleTrain_EN from 'VIDEO_LifestyleTrain_EN'
import LifestyleDog_FRCA from 'VIDEO_LifestyleDog_FRCA'
import LifestyleFriends_FRCA from 'VIDEO_LifestyleFriends_FRCA'
import LifestylePhone_FRCA from 'VIDEO_LifestylePhone_FRCA'
import LifestyleLongboard_FRCA from 'VIDEO_LifestyleLongboard_FRCA'
import LifestyleMotors_FRCA from 'VIDEO_LifestyleMotors_FRCA'
import LifestyleTrain_FRCA from 'VIDEO_LifestyleTrain_FRCA'

const lib = {
  Empty,
  Attract,
  Capture,
  Share,
  Listen,
  Specs,
  Demo,
  LifestyleDog_EN,
  LifestyleFriends_EN,
  LifestylePhone_EN,
  LifestyleLongboard_EN,
  LifestyleMotors_EN,
  LifestyleTrain_EN,
  LifestyleDog_FRCA,
  LifestyleFriends_FRCA,
  LifestylePhone_FRCA,
  LifestyleLongboard_FRCA,
  LifestyleMotors_FRCA,
  LifestyleTrain_FRCA,
}

export default lib