import MultiTapButton from '~/components/MultiTapButton'
import useUiStore from '~/store/ui'

const BSOverlayToggle = () => {
  const { setShowBSOverlay } = useUiStore(state => state)

  return(
    <MultiTapButton
      tapsRequired={5}
      action={() => setShowBSOverlay(true)}
      timeout={3000}
      styles={{
        position: 'absolute',
        top: 0, left: 0,
        zIndex: 2,
        background: 'transparent',
        width: '15vw',
        height: '15vw',
      }} />
  )
}

export default BSOverlayToggle