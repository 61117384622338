import '~/styles/FeatureCapture.scss'
import { useState, useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import gsap from 'gsap'
import FeatureProgressBar from '~/components/FeatureProgressBar'
import FeatureSequence from '~/components/FeatureSequence'
import useUiStore from '~/store/ui'
import useVideoStore from '~/store/video'
import { splitLines } from '~/utils/splitText'
import { multiLine } from '~/utils/anims'
import useSequenceEvents from '~/hooks/useSequenceEvents'

const SEGMENTS = [
  { name: 'Capture-1: Capture', start: 1, end: 5.25 },
  { name: 'Capture-2: Record Video', start: 7.5, end: 16.5 },
  { name: 'Capture-3: Take Photos', start: 19, end: 27.5 },
  { name: 'Capture-4: Capture Hands-Free', start: 30, end: 42 },
]

const FeatureCapture = () => {
  const { transDest, setAdaContent } = useUiStore(state => state)
  const [mountedSequence, setMountedSequence] = useState(false)
  const bgVid = useVideoStore(state => state.vidRef)
  const cnr = useRef()
  const tlRef = useRef()
  const c1 = useRef()
  const c1t = useRef()
  const c1d = useRef()
  const c2 = useRef()
  const c2t = useRef()
  const c2d = useRef()
  const c3 = useRef()
  const c3t = useRef()
  const c3d = useRef()
  const c4 = useRef()
  const c4t = useRef()
  const c4d = useRef()
  const safeToUnmount = useRef(false)
  const progressRef = useRef()
  const { t, i18n } = useTranslation()
  const {
    teardown, initSequence, play, pause, back, next,
  } = useSequenceEvents(
    tlRef,
    bgVid,
    SEGMENTS,
    initTl,
    {
      endDest: '/features/capture/gallery',
      vidSrcKey: 'capture',
    },
  )

  useEffect(() => {
    setAdaContent([{el:'h1',key:'features.capture.c1.title'}])
    return handleUnmount
  }, [])

  useEffect(() => {
    if (transDest) {
      teardown()
      setAdaContent([])
      safeToUnmount.current = true
    }
  }, [transDest])

  function handleUnmount() {
    if (!safeToUnmount.current) {
      teardown()
      setAdaContent([])
      safeToUnmount.current = true
    }
  }

  function initTl() {
    tlRef.current = gsap.timeline({ paused: true })
    const tl = tlRef.current
    const c1Title = c1t.current
    const c1Desc = splitLines(c1d.current)
    const c2Title = c2t.current
    const c2Desc = splitLines(c2d.current)
    const c3Title = c3t.current
    const c3Desc = splitLines(c3d.current)
    const c4Title = c4t.current
    const c4Desc = c4d.current

    gsap.set(progressRef.current, { scaleX: 0 })
    
    tl.add(() => setAdaContent([{el:'h1',key:'features.capture.c1.title'},{el:'p',key:'features.capture.c1.description'}]), SEGMENTS[0].start)
    tl.fromTo(c1Title, { opacity: 0 }, { duration: 0.5, opacity: 1 }, SEGMENTS[0].start)
    tl.fromTo(c1Desc, multiLine.from, multiLine.to, '-=0.33')
    tl.to(progressRef.current, { ease: "none", duration: SEGMENTS[0].end-SEGMENTS[0].start, scaleX: 0.25 }, SEGMENTS[0].start)
    tl.addLabel("c1:in")
    tl.to(c1Desc, multiLine.out, SEGMENTS[0].end)
    tl.to(c1Title, { duration: 0.5, opacity: 0 }, '-=0.33')
    tl.addLabel("c1:out")
    tl.add(() => setAdaContent([{el:'h1',key:'features.capture.c2.title'},{el:'p',key:'features.capture.c2.description'}]), SEGMENTS[1].start)
    tl.fromTo(c2Title, { opacity: 0 }, { duration: 0.5, opacity: 1 }, SEGMENTS[1].start)
    tl.fromTo(c2Desc, multiLine.from, multiLine.to, '-=0.33')
    tl.to(progressRef.current, { ease: "none", duration: SEGMENTS[1].end-SEGMENTS[1].start, scaleX: 0.5 }, SEGMENTS[1].start)
    tl.addLabel("c2:in")
    tl.to(c2Desc, multiLine.out, SEGMENTS[1].end)
    tl.to(c2Title, { duration: 0.5, opacity: 0 }, '-=0.33')
    tl.addLabel("c2:out")
    tl.add(() => setAdaContent([{el:'h1',key:'features.capture.c3.title'},{el:'p',key:'features.capture.c3.description'}]), SEGMENTS[2].start)
    tl.fromTo(c3Title, { opacity: 0 }, { duration: 0.5, opacity: 1 }, SEGMENTS[2].start)
    tl.fromTo(c3Desc, multiLine.from, multiLine.to, '-=0.33')
    tl.to(progressRef.current, { ease: "none", duration: SEGMENTS[2].end-SEGMENTS[2].start, scaleX: 0.75 }, SEGMENTS[2].start)
    tl.addLabel("c3:in")
    tl.to(c3Desc, multiLine.out, SEGMENTS[2].end)
    tl.to(c3Title, { duration: 0.5, opacity: 0 }, '-=0.33')
    tl.addLabel("c3:out")
    tl.add(() => setAdaContent([{el:'h1',key:'features.capture.c4.title'},{el:'p',key:'features.capture.c4.description'}]), SEGMENTS[3].start)
    tl.fromTo(c4Title, { opacity: 0 }, { duration: 0.5, opacity: 1 }, SEGMENTS[3].start)
    tl.fromTo(c4Desc, { opacity: 0 }, { duration: 0.5, opacity: 1 }, '-=0.33')
    tl.to(progressRef.current, { ease: "none", duration: SEGMENTS[3].end-SEGMENTS[3].start, scaleX: 1 }, SEGMENTS[3].start)
    tl.addLabel("c4:in")
    tl.to(c4Desc, { duration: 0.5, opacity: 0 }, SEGMENTS[3].end)
    tl.to(c4Title, { duration: 0.5, opacity: 0 }, '-=0.33')
    tl.add(() => setAdaContent([]), SEGMENTS[3].end)
    tl.addLabel("c4:out")
  }

  function init() {
    initSequence()
    setMountedSequence(true)

    return(() => {
      tlRef.current.kill()
      setMountedSequence(false)
    })
  }

  return(
    <>
      <FeatureSequence action={init} play={play} pause={pause} back={back} next={next}>
        <div id="FeatureCapture" ref={cnr} style={{opacity: mountedSequence ? 1 : 0}}>
          <div className="content-overlay c1" ref={c1}>
            <div className="g1">
              <h1 className="title" ref={c1t}>{t('features.capture.c1.title')}</h1>
              <p className="description" ref={c1d}>{t('features.capture.c1.description')}</p>
            </div>
          </div>
          <div className="content-overlay c2" ref={c2}>
            <div className="g1">
              <h2 className="title" ref={c2t}>{t('features.capture.c2.title')}</h2>
              <p className="description" ref={c2d}>{t('features.capture.c2.description')}</p>
            </div>
          </div>
          <div className="content-overlay c3" ref={c3}>
            <div className="g1">
              <h2 className="title" ref={c3t}>{t('features.capture.c3.title')}</h2>
              <p className="description" ref={c3d}>{t('features.capture.c3.description')}</p>
            </div>
          </div>
          <div className="content-overlay c4" ref={c4}>
            <div className="g1">
              <h2 className="title" ref={c4t}>{t('features.capture.c4.title')}</h2>
              <p className="description" ref={c4d} key={i18n.language}
                  /* ref/key attrs for SplitText issues with Trans */>
                <Trans i18nKey="features.capture.c4.description">
                  Activate Facebook Assistant by saying <strong>“Hey Facebook”</strong> or tap and hold the touchpad. Then give your voice command.
                </Trans>
                <span className="disclaimer">{t('features.capture.c4.disclaimer')}</span>
              </p>
            </div>
          </div>
        </div>
      </FeatureSequence>
      <FeatureProgressBar numSegments={4} ref={progressRef} />
    </>
  )
}

export default FeatureCapture