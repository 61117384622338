import '~/styles/FeaturesNav.scss'
import { useContext, forwardRef, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AnalyticsContext } from '~/context/Analytics'
import useUiStore from '~/store/ui'

import IconHome from '~/assets/images/nav/home.png'
import IconCapture from '~/assets/images/nav/capture.png'
import IconListen from '~/assets/images/nav/listen.png'
import IconShare from '~/assets/images/nav/share.png'
import IconSpecs from '~/assets/images/nav/specs.png'
import IconDemo from '~/assets/images/nav/demo.png'
import IconHomeActive from '~/assets/images/nav/home-active.png'
import IconCaptureActive from '~/assets/images/nav/capture-active.png'
import IconListenActive from '~/assets/images/nav/listen-active.png'
import IconShareActive from '~/assets/images/nav/share-active.png'
import IconSpecsActive from '~/assets/images/nav/specs-active.png'
import IconDemoActive from '~/assets/images/nav/demo-active.png'

const FeaturesNav = forwardRef(({ transTo, hideLabels, touchActive }, ref) => {
  const path = useLocation().pathname
  const highlight = useUiStore(state => state.highlight)
  const hideUi = useUiStore(state => state.hideUi)
  const guidedMode = useUiStore(state => state.guidedMode)
  const { postInteraction, forceCurrentPageLoad } = useContext(AnalyticsContext)
  const [mounted, setMounted] = useState(false)
  const { t } = useTranslation()
  const allowTouch = touchActive && !hideUi

  useEffect(() => {
    setMounted(true)
  }, [])

  function computeCurrent(name) {
    if (highlight) {
      return !!highlight.match(name)
    } else {
      return !!path.match(name)
    }
  }

  function handleClick(navName, path, regExp) {
    postInteraction("navClick", { navName, navType: "Main Nav" })
    if (computeCurrent(regExp)) { forceCurrentPageLoad() }
    return allowTouch && transTo(path)
  }

  return(
    <nav
      id="FeaturesNav"
      style={{
        opacity: hideUi||!mounted ? 0 : 1,
        transition: `opacity 0.33s ease`,
      }}
      className={`${hideLabels ? 'hide-labels' : ''}`} ref={ref}
    >
      <span
        className={`link${computeCurrent(/\/features$/) ? ' current' : ''}`}
        onClick={e => {
          e.stopPropagation()
          handleClick("Home", "/features", /\/features$/)
        }}
      >
        <span className="outline"></span>
        <span className={`icon home`}>
          <span className="outline"></span>
          <img className="passive" width="256" height="256" src={IconHome} alt="Home" />
          <img className="active" width="256" height="256" src={IconHomeActive} alt="Home" />
        </span>
        <span className="label">{ t('features.nav.home') }</span>
      </span>
      <span
        className={`link${computeCurrent('capture') ? ' current' : ''}`}
        onClick={e => {
          e.stopPropagation()
          handleClick("Capture", "/features/capture", 'capture')
        }}
      >
        <span className="outline"></span>
        <span className={`icon capture`}>
          <span className="outline"></span>
          <img className="passive" width="256" height="256" src={IconCapture} alt="Capture" />
          <img className="active" width="256" height="256" src={IconCaptureActive} alt="Capture" />
        </span>
        <span className="label">{ t('features.nav.capture') }</span>
      </span>
      <span
        className={`link${computeCurrent('share') ? ' current' : ''}`}
        onClick={e => {
          e.stopPropagation()
          handleClick("Share", "/features/share", 'share')
        }}
      >
        <span className="outline"></span>
        <span className={`icon share`}>
          <span className="outline"></span>
          <img className="passive" width="256" height="256" src={IconShare} alt="Share" />
          <img className="active" width="256" height="256" src={IconShareActive} alt="Share" />
        </span>
        <span className="label">{ t('features.nav.share') }</span>
      </span>
      <span
        className={`link${computeCurrent('listen') ? ' current' : ''}`}
        onClick={e => {
          e.stopPropagation()
          handleClick("Listen", "/features/listen", 'listen')
        }}
      >
        <span className="outline"></span>
        <span className={`icon listen`}>
          <span className="outline"></span>
          <img className="passive" width="256" height="256" src={IconListen} alt="Listen" />
          <img className="active" width="256" height="256" src={IconListenActive} alt="Listen" />
        </span>
        <span className="label">{ t('features.nav.listen') }</span>
      </span>
      <span
        className={`link${computeCurrent('specs') ? ' current' : ''}`}
        onClick={e => {
          e.stopPropagation()
          handleClick("Tech", "/features/specs", 'specs')
        }}
      >
        <span className="outline"></span>
        <span className={`icon specs`}>
          <span className="outline"></span>
          <img className="passive" width="256" height="256" src={IconSpecs} alt="Tech Specs" />
          <img className="active" width="256" height="256" src={IconSpecsActive} alt="Tech Specs" />
        </span>
        <span className="label">{ t('features.nav.specs') }</span>
      </span>
      { guidedMode &&
        <span
          className={`link${computeCurrent('demo') ? ' current' : ''}`}
          onClick={e => {
            e.stopPropagation()
            handleClick("Demo", "/features/demo", 'demo')
          }}
        >
          <span className="outline"></span>
          <span className={`icon demo`}>
            <span className="outline"></span>
            <img className="passive" width="256" height="256" src={IconDemo} alt="Demo" />
            <img className="active" width="256" height="256" src={IconDemoActive} alt="Demo" />
          </span>
          <span className="label">{ t('features.nav.demo') }</span>
        </span> }
    </nav>
  )
})

export default FeaturesNav