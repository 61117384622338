import '~/styles/LifestyleSingle.scss'
import { useState, useEffect } from 'react'
import useVideoStore from '~/store/video'
import useUiStore from '~/store/ui'
import useBsStore from '~/store/bs'
import useLifestyleEvents from '~/hooks/useLifestyleEvents'

const BLACKOUT_MS = 500

const LifestyleSingle = ({ vidSrcKey, analyticsKey }) => {
  const { setKey: setVidSrcKey, vidRef: bgVid } = useVideoStore(state => state)
  const { setAdaContent, setTransDest, setHideUi, openLoader, teardownLoader } = useUiStore(state => state)
  const { isBrightSign } = useBsStore(state => state)
  const [viewing, setViewing] = useState(false)
  const { bindVideoAnalytics, finVideoAnalytics, cancelVideoAnalytics } = useLifestyleEvents()

  useEffect(() => {
    setAdaContent([{el:'h1',key:'branding.tag'}])
    initVideo()
    setHideUi(true)
    bindVideoAnalytics(bgVid.current, analyticsKey)

    return(() => {
      setHideUi(false)
      cancelVideoAnalytics(bgVid.current)
      bgVid.current.oncanplaythrough = null
      bgVid.current.oncanplay = null
      bgVid.current.onended = null
      bgVid.current.onwaiting = null
      bgVid.current.onplaying = null
    })
  }, [])

  function initVideo() {
    setVidSrcKey(vidSrcKey)
    if (isBrightSign) {
      bgVid.current.oncanplaythrough = onCanPlay
    } else {
      openLoader()
      bgVid.current.oncanplay = onCanPlay
      bgVid.current.onwaiting = onWaiting
      bgVid.current.onplaying = onPlaying
    }
    bgVid.current.onended = onEnded
  }

  function onPlay(e) {
    teardownLoader()
    setViewing(true)
  }

  function onPlaying(e) {
    // teardownLoader()
  }

  function onWaiting(e) {
    // openLoader()
  }

  function onCanPlay(e) {
    bgVid.current.onplay = onPlay
    e.target.play()
  }

  function onEnded(e) {
    setViewing(false)
    setTransDest('/features')
  }

  function close() {
    bgVid.current.pause()
    finVideoAnalytics(bgVid.current)
    cancelVideoAnalytics(bgVid.current)
    setTransDest('/features')
  }

  return (
    <>
      <div
        className="blackout"
        style={{
          opacity: viewing ? 0 : 1,
          transition: `opacity ${BLACKOUT_MS}ms ease`,
        }}
      ></div>
      <span className="video-close" onClick={e => close()}>
        <svg width="100" height="100" viewBox="0 0 100 100">
          <line x1="3" y1="3" x2="97" y2="97" stroke="white" strokeWidth="6" />
          <line x1="3" y1="97" x2="97" y2="3" stroke="white" strokeWidth="6" />
        </svg>
      </span>
    </>
  )
}

export default LifestyleSingle