import '~/styles/index.scss'
import '~/utils/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter as Router } from "react-router-dom"
import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
import { AnalyticsProvider } from '~/context/Analytics'
import App from '~/components/App'
import Scratch from '~/components/Scratch'
import reportWebVitals from '~/reportWebVitals'
import '~/utils/i18n.js'

gsap.registerPlugin(SplitText)

const useScratch = false

ReactDOM.render( useScratch ? <Scratch /> :
  <React.StrictMode>
    <Router>
      <AnalyticsProvider>
        <App />
      </AnalyticsProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
