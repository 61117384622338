const attractTimeout = 30000
const resetTimeoutDur = 30000 // Revert to default language / non-guided within Attract
const attractTimeoutActive = true
const mobileQuery = '(max-width: 600px)'
const enableSequenceControls = false
const analyticsServerUrl = 'http://localhost:9091'
const forceBsUi = (
     new URL(document.location).searchParams.has("forceBsUi")
  || process.env.DIST === 'hs'
  || process.env.DIST === 'xd'
  || process.env.DIST === 'ce'
  || process.env.DIST === 'fb'
)
const guidedMode = (
  new URL(document.location).searchParams.has("guidedMode")
)

export {
  attractTimeout,
  resetTimeoutDur,
  attractTimeoutActive,
  mobileQuery,
  enableSequenceControls,
  forceBsUi,
  analyticsServerUrl,
  guidedMode,
}