import { useCallback, useContext, useRef } from 'react'
import { AnalyticsContext } from '~/context/Analytics'

const useDemoEvents = () => {
  const { postInteraction } = useContext(AnalyticsContext)
  const analyticsName = useRef('')
  const ckptRef = useRef([false, false, false])

  const handleTimeupdate = useCallback((e) => {
    const current = e.target.currentTime
    const duration = e.target.duration
    const pct = current / duration
    const videoName = analyticsName.current
    if (pct > 0.75 && !ckptRef.current[2]) {
      ckptRef.current[2] = true
      postInteraction("video75", { videoName, videoLength: duration })
    } else if (pct > 0.5 && !ckptRef.current[1]) {
      ckptRef.current[1] = true
      postInteraction("video50", { videoName, videoLength: duration })
    } else if (pct > 0.25 && !ckptRef.current[0]) {
      ckptRef.current[0] = true
      postInteraction("video25", { videoName, videoLength: duration })
    }
  }, [])

  const handleEnded = useCallback((e) => {
    const videoName = analyticsName.current
    postInteraction("video100", { videoName, videoLength: e.target.duration })
    finVideoAnalytics(e.target)
    cancelVideoAnalytics(e.target)
  }, [])

  const handlePlay = useCallback((e) => {
    e.target.removeEventListener('play', handlePlay)
    const videoName = analyticsName.current
    postInteraction("videoStart", { videoName, videoLength: e.target.duration })
  }, [])

  function bindVideoAnalytics(vid, videoName) {
    analyticsName.current = videoName
    vid.addEventListener('timeupdate', handleTimeupdate)
    vid.addEventListener('ended', handleEnded)
    vid.addEventListener('play', handlePlay)
  }
  
  function cancelVideoAnalytics(vid, videoName) {
    analyticsName.current = ''
    ckptRef.current = [false, false, false]
    if (vid) { // Destroyed if part of Demo component
      vid.removeEventListener('timeupdate', handleTimeupdate)
      vid.removeEventListener('ended', handleEnded)
      vid.removeEventListener('play', handlePlay)
    }
  }

  function finVideoAnalytics(vid) {
    const videoName = analyticsName.current
    const videoPlayedTime = +(vid.currentTime).toFixed()
    const videoLengthTime = +(vid.duration).toFixed()
    postInteraction(`videoPlayed=${videoPlayedTime}`, { videoName, videoPlayedTime })
    postInteraction(`videoLength=${videoLengthTime}`, { videoName, videoLengthTime })
  }

  return {
    bindVideoAnalytics,
    cancelVideoAnalytics,
    finVideoAnalytics,
  }
}

export default useDemoEvents