import create from 'zustand'
import vlib from '~/constants/video-lib-dist'

const useStore = create((set, get) => ({
  key: '',
  setKey(key) {
    // set(() => ({ key, loop: key === 'attract' }))
    set(() => ({ key }))
  },

  vidRef: null,
  setVidRef(vidRef) {
    set(() => ({ vidRef }))
  },

  loop: false,
  setLoop(loop) {
    set(() => ({ loop }))
  },

  lib: vlib,
  setLib(lib) {
    set(() => ({ lib }))
  },

  async fetchBlob(key) {
    const { lib, setLib } = get()
    const res = await fetch(lib[key])
    const blob = await res.blob()
    const url = URL.createObjectURL(blob)
    setLib({ ...lib, [key]: url })
  },
}))

export default useStore