import '~/styles/Loader.scss'
import LoaderSVG from '~/components/LoaderOverlay'
import useUiStore from '~/store/ui'
const Loader = () => {
  const { showLoader, renderLoader } = useUiStore(state => state)
  return(
    <div id="Loader" style={{
      display: renderLoader ? 'flex' : 'none',
    }}>
      <div id="LoaderOverlay" style={{
        // opacity: showLoader ? 1 : 0,
        // transition: `opacity 1s ease`,
      }}>
        <LoaderSVG />
        {/* <span>LOADING...</span> */}
      </div>
    </div>
  )
}

export default Loader