import { useState, useRef, useContext } from 'react'
import { AnalyticsContext } from '~/context/Analytics'
import Keyboard from "react-simple-keyboard"
import "react-simple-keyboard/build/css/index.css"

const LAYOUT_DEFAULT = ["1 2 3", "4 5 6", "7 8 9", "{bksp} 0 {enter}"]
const PASSCODE = '3739'
const PASSCODE_ARRAY = new Array(PASSCODE.length).fill()

const BSOverlayAccess = ({ onSuccess }) => {
  const [input, setInput] = useState('')
  const { postInteraction } = useContext(AnalyticsContext)
  const keyboardRef = useRef()

  function onChange(input) {
    if (input.length > PASSCODE.length) { return }
    setInput(input)
  }

  function onChangeInput(event) {
    const input = event.target.value
    setInput(input)
    keyboardRef.current.setInput(input)
  }

  function onKeyPress(key) {
    if (key === '{enter}') {
      postInteraction("passwordSubmitAttempt")
      if (input === PASSCODE) { onSuccess() }
    }
  }

  return(
    <div id="BSOverlayAccess">
      <input
        type="text"
        style={{display: 'none'}}
        maxLength={4}
        value={input}
        onChange={onChangeInput}
      />
      <div className="passcode">
      {
        PASSCODE_ARRAY.map((_,i) => {
          return <span key={i} style={{width: `calc(${100/PASSCODE.length}% - 0.25em)`}}>{input[i] || '*'}</span>
        })
      }
      </div>
      <Keyboard
        keyboardRef={r => keyboardRef.current = r}
        layout={{
          default: LAYOUT_DEFAULT,
        }}
        theme={"hg-theme-default hg-layout-numeric numeric-theme"}
        onChange={onChange}
        onKeyPress={onKeyPress}
        maxLength={PASSCODE.length}
        display={{
          '{bksp}': 'delete',
          '{enter}': 'submit',
        }}
      />
    </div>
  )
}

export default BSOverlayAccess