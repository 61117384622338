import { useEffect, useRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import useUiStore from '~/store/ui'
import useBsStore from '~/store/bs'
import useVideoStore from '~/store/video'
import usePrevious from '~/hooks/usePrevious'
import { AnalyticsContext } from '~/context/Analytics'

const useSequenceEvents = (tlRef, bgVid, segments, tlSetupFunc, options) => {
  const setTransDest = useUiStore(state => state.setTransDest)
  const openLoader = useUiStore(state => state.openLoader)
  const teardownLoader = useUiStore(state => state.teardownLoader)
  const setVidSrcKey = useVideoStore(state => state.setKey)
  const isBrightSign = useBsStore(state => state.isBrightSign)
  const seeking = useRef(false)
  const { i18n } = useTranslation()
  const prevLanguage = usePrevious(i18n.language)
  const { postInteraction } = useContext(AnalyticsContext)

  useEffect(() => {
    if (prevLanguage) {
      let tlNow = tlRef.current.time()
      tlRef.current.kill()
      initTl()
      seekWithoutAnalytics(tlNow)
      tlRef.current.play()
    }
  }, [i18n.language])

  useEffect(() => {
    return () => {
      const currentTime = tlRef.current.time()
      const tlDuration = tlRef.current.duration()
      if (currentTime < tlDuration) {
        const seg = findSegmentAt(currentTime)
        const videoPlayedTime = +(currentTime - seg.start).toFixed(2)
        const videoLengthTime = +(seg.end - seg.start).toFixed(2)
        postInteraction(
          `videoPlayed=${videoPlayedTime}`,
          { videoName: seg.name, videoPlayedTime }
        )
        postInteraction(
          `videoLength=${videoLengthTime}`,
          { videoName: seg.name, videoLengthTime }
        )
      }
    }
  }, [])

  function teardown() {
    tlRef.current?.kill()
    bgVid.current.onplay = null
    bgVid.current.onpause = null
    bgVid.current.oncanplaythrough = null
    bgVid.current.oncanplay = null
    bgVid.current.onplaying = null
    bgVid.current.onwaiting = null
    bgVid.current.onended = null
    bgVid.current.ontimeupdate = null
  }

  function initVideo() {
    setVidSrcKey(options.vidSrcKey)
    if (isBrightSign) {
      bgVid.current.oncanplaythrough = onCanPlay
    } else {
      openLoader()
      bgVid.current.oncanplay = onCanPlay
    }
    bgVid.current.onplaying = onPlaying
    bgVid.current.onwaiting = onWaiting
    bgVid.current.onplay = onPlay
    bgVid.current.onpause = onPause
    bgVid.current.onended = onEnded
  }

  function initTl() {
    tlSetupFunc()
    segments.forEach(s => {
      const videoName = s.name
      const videoLength = s.end - s.start
      const videoPlayedTime = +videoLength.toFixed(2)
      const videoLengthTime = +videoLength.toFixed(2)
      addAnalytics(() => postInteraction("videoStart", { videoName, videoLength }), s.start + 0.001) // Offset to avoid suppressAnalytics...
      addAnalytics(() => postInteraction("video25", { videoName, videoLength }), s.start + videoLength * 0.25)
      addAnalytics(() => postInteraction("video50", { videoName, videoLength }), s.start + videoLength * 0.50)
      addAnalytics(() => postInteraction("video75", { videoName, videoLength }), s.start + videoLength * 0.75)
      addAnalytics(() => postInteraction("video100", { videoName, videoLength }), s.end)
      addAnalytics(() => postInteraction(`videoPlayed=${videoPlayedTime}`, { videoName, videoPlayedTime }), s.end)
      addAnalytics(() => postInteraction(`videoLength=${videoLengthTime}`, { videoName, videoLengthTime }), s.end)
    })
  }

  function initSequence() {
    initTl()
    initVideo()
  }

  function onPlay() {
    teardownLoader()
    tlRef.current.play()
  }

  function onPause() {
    tlRef.current.pause()
  }

  function onWaiting() {
    tlRef.current.pause()
  }
  
  function onPlaying() {
    tlRef.current.play()
  }

  function onCanPlay() {
    bgVid.current.play()
  }

  function onEnded() {
    setTransDest(options.endDest)
  }

  function play() {
    bgVid.current.play()
  }

  function pause() {
    bgVid.current.pause()
  }

  function back() {
    seeking.current = true
    let currTime = tlRef.current.time()
    let destSeconds = 0
    pause()
    for (let idx = segments.length - 1; idx > 0; idx--) {
      if (currTime >= segments[idx].start) {
        destSeconds = segments[idx - 1].start
        break
      }
    }
    bgVid.current.ontimeupdate = onTimeUpdate
    bgVid.current.currentTime = destSeconds
    const reportedSegment = findSegmentAt(currTime)
    if (reportedSegment) {
      postInteraction("scrubBackward", { videoName: reportedSegment.name, videoLength: reportedSegment.end - reportedSegment.start })
    }
  }

  function next() {
    let currTime = tlRef.current.time()
    let destSeconds = 0
    if (currTime >= segments[segments.length - 1].end) { return } // we're at the end
    pause()
    for (let idx = 0; idx < segments.length; idx++) {
      if (idx === segments.length - 1) {
        destSeconds = segments[idx].end
        break
      }
      if (currTime <= segments[idx + 1].start) {
        destSeconds = segments[idx + 1].start
        break
      }
    }
    bgVid.current.ontimeupdate = onTimeUpdate
    bgVid.current.currentTime = destSeconds
    const reportedSegment = findSegmentAt(currTime)
    if (reportedSegment) {
      postInteraction("scrubForward", { videoName: reportedSegment.name, videoLength: reportedSegment.end - reportedSegment.start })
    }
  }

  function onTimeUpdate() {
    bgVid.current.ontimeupdate = null
    seekWithoutAnalytics(bgVid.current.currentTime)
    bgVid.current.play()
  }

  function seek(to) {
    bgVid.current.ontimeupdate = onTimeUpdate
    bgVid.current.currentTime = to
  }

  function seekWithoutAnalytics(to) {
    tlRef.current.suppressAnalytics = true
    tlRef.current.time(to)
    tlRef.current.suppressAnalytics = false
    return tlRef.current
  }

  function addAnalytics(func, position) {
    return tlRef.current.add(() => tlRef.current.suppressAnalytics || func(), position)
  }

  function findSegmentAt(time) {
    return segments.find(s => s.end > time && s.start < time)
  }

  return {
    teardown,
    initSequence,
    play,
    pause,
    back,
    next,
    seek,
  }
}

export default useSequenceEvents