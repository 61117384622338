import '~/styles/App.scss'
import { useEffect, useState, useContext, useCallback } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Attract from '~/components/Attract'
import Features from '~/components/Features'
import LanguageToggle from '~/components/LanguageToggle'
import ADAToggle from '~/components/ADAToggle'
import ADAPanel from '~/components/ADAPanel'
import VideoLayer from '~/components/VideoLayer'
import ErrorOverlays from '~/components/ErrorOverlays'
import BSOverlay from '~/components/BSOverlay'
import BSOverlayToggle from '~/components/BSOverlayToggle'
import Loader from '~/components/Loader'
import useUiStore from '~/store/ui'
import useBsStore from '~/store/bs'
import useVideoStore from '~/store/video'
import { forceBsUi } from '~/config'
import { AnalyticsContext } from '~/context/Analytics'
import manifestDefault from '~/assets/manifest/default.json'
import manifestGuided from '~/assets/manifest/guided.json'

function App() {
  const [loaded, setLoaded] = useState(false)
  const { i18n } = useTranslation()
  const location = useLocation()
  const { isBrightSign, fetchUserVars, initBs } = useBsStore(state => state)
  const { fetchBlob } = useVideoStore(state => state)
  const { postInteraction } = useContext(AnalyticsContext)
  const {
    showBSOverlay,
    renderAda,
    setRenderAda,
    interactive,
    setInteractive,
    guidedMode,
    defaultLanguage,
    setDefaultLanguage,
    frenchDialect,
    setFrenchDialect,
    openLoader,
    teardownLoader,
    appBounds,
    setAppBounds,
  } = useUiStore(state => state)
  const useBsUi = isBrightSign || forceBsUi

  const handleLanguageChange = useCallback(() => {
    if (!loaded) { return }
    postInteraction("languageChange", { languageCurrent: i18n.language.toUpperCase() })
  }, [loaded, i18n.language])

  const handleGuidedModeToggle = useCallback(() => {
    if (!loaded) { return }
    postInteraction(`guidedMode${guidedMode?'On':'Off'}`)
  }, [loaded, guidedMode])

  useEffect(() => {
    init()
    window.addEventListener("resize", resize)
    return(() => {
      window.removeEventListener('load', handleLoaded)
      window.removeEventListener("resize", resize)
    })
  }, [])

  useEffect(() => {
    const rootFontSize = `${.015 * appBounds.w}px`
    document.querySelector('html').style.fontSize = rootFontSize
  }, [appBounds])

  useEffect(() => {
    if (defaultLanguage && location.pathname === '/') {
      i18n.changeLanguage(defaultLanguage)
    }
  }, [defaultLanguage])

  useEffect(() => {
    if (i18n.language.match('fr')) {
      i18n.changeLanguage(frenchDialect)
    }
  }, [frenchDialect])

  useEffect(() => {
    handleLanguageChange()
  }, [i18n.language])
  
  // useEffect(() => {
  //   handleGuidedModeToggle()
  // }, [guidedMode])

  function resize() {
    if ((new URL(document.location)).searchParams.get("windowDims")) { return }
    else { setAppBounds({x: 0, y: 0, w: window.innerWidth, h: window.innerHeight}) }
  }

  async function init() {
    // makeManifest()

    /* Process BrightSign User Variables */
    const web = process.env.DIST === 'web'
    if (!web) { initBs() }
    if (!isBrightSign) { openLoader() }
    const [userVars, uvError] = await (web ? Promise.resolve([{}, null]) : fetchUserVars())
    let lang
    if (userVars?.FrenchDialect) { setFrenchDialect(userVars.FrenchDialect) }
    if (userVars?.LanguageDefault) {
      lang = userVars.LanguageDefault
    } else if (navigator?.languages) {
      let navLangs = navigator.languages
      for (let i = 0; i < navLangs.length; i++) {
        let l = navLangs[i]
        if (l === 'fr-CA') { setFrenchDialect('fr-CA') }
        if (i18n.store.data[l]) {
          lang = l
          break
        } else if (i18n.store.data[l.split('-')[0]]) {
          lang = l.split('-')[0]
          break
        }
      }
    } else {
      lang = 'en'
    }

    i18n.changeLanguage(lang) // In case we're not on the attract page
    setDefaultLanguage(lang)

    let renderAda = true
    if (userVars?.WithAda) {
      renderAda = !!parseInt(userVars.WithAda)
    }
    setRenderAda(renderAda)
    
    if (userVars?.Interactive) {
      setInteractive(!!parseInt(userVars.Interactive))
    }

    if (guidedMode) {
      await fetchBlob("Demo")
    }

    if (document.readyState === 'complete') {
      handleLoaded()
    } else {
      window.addEventListener('load', handleLoaded)
    }
    return(() => {
      window.removeEventListener('load', handleLoaded)
    })
  }

  function handleLoaded() {
    window.setTimeout(() => {
      setLoaded(true)
      if (!isBrightSign) { teardownLoader() }
    }, 1000)
  }

  function makeManifest() {
    const raw = guidedMode ? manifestGuided : manifestDefault
    const content = window.encodeURIComponent(JSON.stringify(raw))
    const url = `data:application/manifest+json,${content}`
    const el = document.createElement('link')
    el.setAttribute('rel', 'manifest')
    el.setAttribute('href', url)
    document.querySelector('head').appendChild(el)
  }

  return (
    <div
      data-lang={i18n.language}
      data-dist={process.env.DIST}
      className={`App${useBsUi ? ' is-brightsign' : ''}`}
      style={{
        left: `${appBounds.x}px`,
        top: `${appBounds.y}px`,
        width: `${appBounds.w}px`,
        height: `${appBounds.h}px`,
      }}
    >
      <VideoLayer />
      {
        loaded &&
        <>
        <Switch>
          <Route path="/" exact>
            <Attract />
          </Route>
          <Route path="/features">
            <Features />
          </Route>
        </Switch>
        { (useBsUi) && 
          <>
          { interactive &&
            <div id="BSToggles">
              <LanguageToggle />
              { renderAda && <ADAToggle /> }
            </div> }
            { renderAda && <ADAPanel /> }
          </> }
        </>
      }
      <ErrorOverlays />
      { useBsUi && (
        showBSOverlay
        ? <BSOverlay />
        : <BSOverlayToggle />
      )}
      { !isBrightSign && <Loader /> }
    </div>
  );
}

export default App;
