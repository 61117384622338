import LoaderSVG from '~/components/LoaderSVG'

const LoaderOverlay = () => {
  return(
    <div id="LoaderOverlay">
      <LoaderSVG />
    </div>
  )
}

export default LoaderOverlay