import '~/styles/FeaturesNavMobile.scss'
import { forwardRef, useEffect, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import useUiStore from '~/store/ui'
import { AnalyticsContext } from '~/context/Analytics'

import IconHome from '~/assets/images/nav/home.png'
import IconCapture from '~/assets/images/nav/capture.png'
import IconListen from '~/assets/images/nav/listen.png'
import IconShare from '~/assets/images/nav/share.png'
import IconSpecs from '~/assets/images/nav/specs.png'
import IconHomeActive from '~/assets/images/nav/home-active.png'
import IconCaptureActive from '~/assets/images/nav/capture-active.png'
import IconListenActive from '~/assets/images/nav/listen-active.png'
import IconShareActive from '~/assets/images/nav/share-active.png'
import IconSpecsActive from '~/assets/images/nav/specs-active.png'
import { useTranslation } from 'react-i18next'

const FeaturesNavMobile = forwardRef(({ transTo, hideLabels, touchActive }, ref) => {
  const path = useLocation().pathname
  const highlight = useUiStore(state => state.highlight)
  const hideUi = useUiStore(state => state.hideUi)
  const [mounted, setMounted] = useState(false)
  const { postInteraction, forceCurrentPageLoad } = useContext(AnalyticsContext)
  const { t } = useTranslation()

  useEffect(() => {
    setMounted(true)
  }, [])

  function computeCurrent(name) {
    if (highlight) {
      return !!highlight.match(name)
    } else {
      return !!path.match(name)
    }
  }

  function handleClick(navName, path, regExp) {
    postInteraction("navClick", { navName, navType: "Main Nav" })
    if (computeCurrent(regExp)) { forceCurrentPageLoad() }
    return touchActive && transTo(path)
  }

  return(
    <nav
      id="FeaturesNavMobile"
      style={{
        opacity: hideUi||!mounted ? 0 : 1,
        transition: `opacity 0.33s ease`,
      }}
      className={`${hideLabels ? 'hide-labels' : ''}`}
      ref={ref}
    >
      <span
        className={`link${computeCurrent(/\/features$/) ? ' current' : ''}`}
        onClick={() => handleClick("Home", "/features", /\/features$/)}
      >
        <span className="outline"></span>
        <span className={`icon home`}>
          <span className="outline"></span>
          <img className="passive" width="256" height="256" src={IconHome} alt="Home" />
          <img className="active" width="256" height="256" src={IconHomeActive} alt="Home" />
        </span>
        <span className="label">{ t('features.nav.home') }</span>
      </span>
      <span
        className={`link${computeCurrent('capture') ? ' current' : ''}`}
        onClick={_ => handleClick("Capture", "/features/capture", 'capture')}
      >
        <span className="outline"></span>
        <span className={`icon capture`}>
          <span className="outline"></span>
          <img className="passive" width="256" height="256" src={IconCapture} alt="Capture" />
          <img className="active" width="256" height="256" src={IconCaptureActive} alt="Capture" />
        </span>
        <span className="label">{ t('features.nav.capture') }</span>
      </span>
      <span
        className={`link${computeCurrent('share') ? ' current' : ''}`}
        onClick={_ => handleClick("Share", "/features/share", 'share')}
      >
        <span className="outline"></span>
        <span className={`icon share`}>
          <span className="outline"></span>
          <img className="passive" width="256" height="256" src={IconShare} alt="Share" />
          <img className="active" width="256" height="256" src={IconShareActive} alt="Share" />
        </span>
        <span className="label">{ t('features.nav.share') }</span>
      </span>
      <span
        className={`link${computeCurrent('listen') ? ' current' : ''}`}
        onClick={_ => handleClick("Listen", "/features/listen", 'listen')}
      >
        <span className="outline"></span>
        <span className={`icon listen`}>
          <span className="outline"></span>
          <img className="passive" width="256" height="256" src={IconListen} alt="Listen" />
          <img className="active" width="256" height="256" src={IconListenActive} alt="Listen" />
        </span>
        <span className="label">{ t('features.nav.listen') }</span>
      </span>
      <span
        className={`link${computeCurrent('specs') ? ' current' : ''}`}
        onClick={_ => handleClick("Tech", "/features/specs", 'specs')}
      >
        <span className="outline"></span>
        <span className={`icon specs`}>
          <span className="outline"></span>
          <img className="passive" width="256" height="256" src={IconSpecs} alt="Tech Specs" />
          <img className="active" width="256" height="256" src={IconSpecsActive} alt="Tech Specs" />
        </span>
        <span className="label">{ t('features.nav.specs') }</span>
      </span>
    </nav>
  )
})

export default FeaturesNavMobile