import * as React from "react"

const LoaderSVG = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 969.86 645.6"
  >
    <title>{"Loading"}</title>
    <g className="glasses" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round">
      <path d="M271.53 277.45c3.52-5.16 2.84-8.76-2.33-12.28s-8.76-2.83-12.28 2.33-2.83 8.76 2.33 12.28 8.75 2.83 12.28-2.33z" />
      <path d="M484.93 295a20.45 20.45 0 00-6.58 1.09 16.72 16.72 0 00-6.84 4.27c-1.8 1.92-3 4.32-4.93 9.13s-4.56 12-7.51 19.4a198.16 198.16 0 01-10.21 22.28A107.68 107.68 0 01418 387a45.68 45.68 0 01-15.07 8 139 139 0 01-25.77 5.35 157.64 157.64 0 01-32.43.54A168.27 168.27 0 01315 395c-8.23-2.34-14.12-4.62-18.86-7.62a39.78 39.78 0 01-11.35-11c-3-4.26-5.41-9.07-8.23-18.49s-6.07-23.49-8.71-33.7-4.68-16.58-6.49-20.72a21.32 21.32 0 00-5-7.63 19.22 19.22 0 00-5.15-3.5 39.08 39.08 0 01-4.15-1.67c-.95-.65-1.32-1.67-1.61-4.06s-.49-6.16-.66-11.2-.3-11.35-.24-15.08.34-4.89.95-5.61 1.56-1 6.28-1.92 13.2-2.34 26.14-3.92 30.35-3.27 42.83-4.06a238.1 238.1 0 0130.69-.1 373.29 373.29 0 0142.46 4.45c18.14 3 40.72 7.87 55.19 11.18s20.85 5 25.65 6.06a42.45 42.45 0 0020.35 0c4.81-1.08 11.17-2.76 25.65-6.06s37-8.23 55.19-11.18a373.46 373.46 0 0142.47-4.45 237.94 237.94 0 0130.68.1c12.49.79 29.89 2.48 42.83 4.06s21.43 3 26.14 3.92 5.67 1.19 6.28 1.92.88 1.88.95 5.61-.07 10-.24 15.08-.37 8.8-.66 11.2-.66 3.41-1.61 4.06a36.32 36.32 0 01-4.15 1.67 19.62 19.62 0 00-5.15 3.5c-1.68 1.56-3.24 3.49-5 7.63S704.63 314 702 324.22s-5.88 24.27-8.71 33.7-5.29 14.23-8.29 18.49a39.52 39.52 0 01-11.35 11c-4.74 3-10.63 5.28-18.86 7.62a166.89 166.89 0 01-29.73 5.77 157.64 157.64 0 01-32.43-.54 139 139 0 01-25.77-5.35 45.78 45.78 0 01-15.07-8A108 108 0 01521 351.13a198.16 198.16 0 01-10.21-22.28c-2.95-7.39-5.59-14.6-7.51-19.4s-3.12-7.21-4.93-9.13a16.59 16.59 0 00-6.84-4.27 20.5 20.5 0 00-6.58-1.05z" />
      <path d="M600.17 261.1c7.5-.42 17.43-.88 25.59-1.09a161.94 161.94 0 0120.19.4 68.17 68.17 0 0114.62 3 37 37 0 0111.16 5.59 33.09 33.09 0 019 10.2c2.32 4.17 3.7 9 4.27 15.71a123.67 123.67 0 01-.92 25.07 255.53 255.53 0 01-5.57 29.4 84.5 84.5 0 01-6.14 17.47 34.86 34.86 0 01-6.09 8.83 26.1 26.1 0 01-7.63 5.41 64.4 64.4 0 01-14.84 5.13 136.19 136.19 0 01-25.62 3 168.31 168.31 0 01-31.07-1.9 86.93 86.93 0 01-23.58-7.17 37.37 37.37 0 01-14.12-11.39 109 109 0 01-12.59-21.63 170.9 170.9 0 01-10-28.11 70.21 70.21 0 01-2.33-21.4 28 28 0 013.75-13c2.07-3.41 4.92-6.17 9.8-8.92a95.54 95.54 0 0119.57-7.7 196.89 196.89 0 0123.16-5 185.43 185.43 0 0119.39-1.9zM698.32 277.45c-3.52-5.16-2.83-8.76 2.33-12.28s8.76-2.83 12.28 2.33 2.83 8.76-2.33 12.28-8.76 2.83-12.28-2.33zM369.68 261.1c-7.5-.42-17.43-.88-25.59-1.09a162 162 0 00-20.19.4 68.17 68.17 0 00-14.62 3 37 37 0 00-11.16 5.59 33.33 33.33 0 00-9 10.2c-2.32 4.17-3.7 9-4.27 15.71a124.37 124.37 0 00.92 25.07 259.9 259.9 0 005.57 29.4 85.09 85.09 0 006.14 17.47 34.86 34.86 0 006.09 8.83 26.1 26.1 0 007.63 5.41 64.17 64.17 0 0014.8 5.1 136.38 136.38 0 0025.62 3 168.25 168.25 0 0031.07-1.9 86.89 86.89 0 0023.59-7.17 37.27 37.27 0 0014.11-11.39A109.4 109.4 0 00433 347.09 171 171 0 00443 319a70.2 70.2 0 002.32-21.4 27.77 27.77 0 00-3.75-13.05c-2.07-3.41-4.92-6.17-9.8-8.92a94.52 94.52 0 00-19.54-7.63 196.46 196.46 0 00-23.16-5 185.17 185.17 0 00-19.39-1.9z" />
    </g>
    <g className="radial" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round">
      <path d="M730.34 310.32a2.73 2.73 0 011.2-1.08A28.67 28.67 0 00739 303a24 24 0 004.46-8.18 32.63 32.63 0 001.11-5.7 209.21 209.21 0 001-29.08 36.33 36.33 0 00-1.31-10 24.41 24.41 0 00-3.39-6.91 22.2 22.2 0 00-3.39-3.79 23 23 0 00-7.19-4.3 49 49 0 00-8.53-2.11c-6-1.13-12.08-2.06-18.15-2.9-13.24-1.84-26.55-3.23-39.87-4.36-4.78-.41-9.55-.78-14.33-1.08s-9.58-.5-14.37-.52c-4 0-8 .08-12.08.23s-8 .32-12.08.58c-11.62.73-23.2 2.08-34.71 3.81-8.62 1.29-17.21 2.79-25.76 4.56s-17.07 3.8-25.6 5.53c-6.67 1.35-13.34 2.51-20 4.35s-13.16 4.36-20 4.31-13.37-2.44-20-4.28-13.28-3-19.94-4.4C436.48 237 428 235 419.41 233.2s-17.15-3.32-25.77-4.62q-17.26-2.61-34.71-3.79c-8-.53-16.12-.8-24.18-.7-9.57.11-19.12.75-28.67 1.52a557.5 557.5 0 00-58 7.24 49.69 49.69 0 00-8.57 2.12 23.3 23.3 0 00-7.19 4.31 22.27 22.27 0 00-3.39 3.8 24.41 24.41 0 00-3.39 6.91 36.71 36.71 0 00-1.3 10 210.77 210.77 0 001 29.08 33.11 33.11 0 001.1 5.66 24.24 24.24 0 004.45 8.2 29 29 0 007.48 6.31c.39.23.78.45 1.18.66a9.37 9.37 0 014.85 5.46 129.32 129.32 0 014.49 16.3c2.81 12.19 5.61 24.32 9.67 36.09a87 87 0 008.67 18.85 57.93 57.93 0 0012.29 13.7 69.71 69.71 0 0016.81 9.75 135.12 135.12 0 0021.44 6.68 185 185 0 0027.76 4.52 173.16 173.16 0 0029.25-.31 177.46 177.46 0 0025.52-4.17 87.27 87.27 0 0019.17-6.72 85 85 0 0014.84-10 126.93 126.93 0 0029.55-34.13 172.86 172.86 0 0010-19.66c.58-1.32 1.14-2.64 1.7-4a10.16 10.16 0 0118.75 0c.56 1.33 1.12 2.65 1.7 4A172.46 172.46 0 00506 365.92a126.9 126.9 0 0029.54 34.13 86.33 86.33 0 0014.84 10 87.48 87.48 0 0019.17 6.73 177.43 177.43 0 0025.6 4.19 173.4 173.4 0 0029.27.35 189.87 189.87 0 0027.74-4.55 133.59 133.59 0 0021.5-6.63 68.79 68.79 0 0016.74-9.81 58.38 58.38 0 0012.32-13.68 85.57 85.57 0 008.66-18.85c4-11.78 6.87-23.89 9.67-36.09a136 136 0 014.24-15.71 9.35 9.35 0 015.05-5.68z" />
      <path d="M746.18 323.85q2-1.5 3.89-3.2a45.85 45.85 0 005.66-6.16 44.5 44.5 0 005.1-8.48 45.74 45.74 0 002.64-7.53c1.51-5.88 1.85-12 2.15-18 .46-9.13.84-18.27-.15-27.33a51.1 51.1 0 00-1.74-9.18 42.48 42.48 0 00-3.09-7.41 44.64 44.64 0 00-7.43-10.35 42.17 42.17 0 00-12.91-9 64.32 64.32 0 00-14.76-4.27c-4.95-.93-9.93-1.72-14.91-2.46s-10-1.4-15-2c-7.91-1-15.84-1.74-23.77-2.48S656 204.47 648 204.06c-5.35-.27-10.69-.41-16-.39q-7.35 0-14.7.4t-14.65 1c-10.55.89-21.1 2.07-31.56 3.72s-20.84 3.73-31.21 5.88c-12.19 2.52-24.38 5.11-36.47 7.82-3.08.69-6.16 1.39-9.21 2.36s-6.09 2.21-9.26 2.19c-3.65 0-7.12-1.42-10.63-2.55a106.23 106.23 0 00-10.57-2.69c-7.1-1.52-14.28-2.86-21.41-4.42-5.83-1.28-11.62-2.72-17.45-3.93s-11.71-2.22-17.59-3.21c-9-1.5-18-3-27.08-4a358.48 358.48 0 00-50.42-2.24c-11.48.28-22.94 1-34.38 2.05-17.11 1.52-34.21 3.65-51.09 6.81a63.59 63.59 0 00-14.79 4.28 42.35 42.35 0 00-12.91 9.06 44.7 44.7 0 00-7.43 10.36 43.18 43.18 0 00-3.07 7.41 50.88 50.88 0 00-1.74 9.16c-1 9.06-.59 18.17-.15 27.3.3 6.07.62 12.14 2.13 18A46.21 46.21 0 00209 306a44.6 44.6 0 005.07 8.51 48.63 48.63 0 009.61 9.4 9.43 9.43 0 013.74 5.53c1.28 4.8 2.3 9.67 3.4 14.52a279 279 0 008.73 31.22 114.42 114.42 0 008.5 19.14 78.67 78.67 0 0012.68 16.48 81.06 81.06 0 0016.49 12.52 107.84 107.84 0 0018.88 8.44 182.78 182.78 0 0020.8 5.7 205.79 205.79 0 0025.4 4 197.65 197.65 0 0051-2.42 152.6 152.6 0 0021.1-5 94.88 94.88 0 0018.75-8.23 111.78 111.78 0 0014.78-10.6 157 157 0 0015-14.17 147.22 147.22 0 0013.65-17.34 10.16 10.16 0 0116.86 0 123.21 123.21 0 0013.4 17.3 156 156 0 0015 14.2 111.27 111.27 0 0014.75 10.57 95.75 95.75 0 0018.67 8.23 151.18 151.18 0 0021.2 5 216.75 216.75 0 0024.1 2.75 184.34 184.34 0 0027-.3 209 209 0 0025.38-4 185 185 0 0020.83-5.62 103.11 103.11 0 0019-8.52A82.23 82.23 0 00709 410.77a78.56 78.56 0 0012.7-16.39 111.59 111.59 0 008.52-19.2 275.72 275.72 0 008.69-31c1.13-5 2.16-9.92 3.47-14.82a9.45 9.45 0 013.8-5.51z" />
      <path d="M763.58 335.84c1.19-1.07 2.35-2.19 3.45-3.34a65.4 65.4 0 008.76-11.5 63.73 63.73 0 006.11-13c2.16-6.59 3-13.5 3.59-20.43a197.9 197.9 0 00.42-33.73 79.5 79.5 0 00-2.76-15.84 60.65 60.65 0 00-5.48-12.65 65.43 65.43 0 00-13.53-16.69 60 60 0 00-10.91-7.66 66.74 66.74 0 00-11.1-4.76c-8.11-2.69-16.57-4.07-25-5.33q-29.07-4.31-58.36-6.44c-7.39-.52-14.8-.94-22.2-1-9.46-.13-18.93.27-28.38.92-12 .81-24 2-35.87 3.77-19.48 2.86-38.71 7.16-58 11.23-8.91 1.88-17.83 3.72-26.54 6.32a9.62 9.62 0 01-6-.07l-1.21-.39c-8.66-2.79-17.66-4.31-26.59-6.12s-17.78-3.88-26.68-5.75c-13.08-2.74-26.28-5-39.56-6.64a408.47 408.47 0 00-48.94-3c-13 0-25.91.6-38.83 1.6-17.58 1.36-35.09 3.45-52.56 6.39-8.06 1.36-16.11 2.9-23.65 5.92a63 63 0 00-19.63 12.5 65.24 65.24 0 00-11.8 15.1 60.66 60.66 0 00-5.17 11.51 70.74 70.74 0 00-2.78 12.8c-1.2 9.39-1 18.85-.58 28.33.34 8.65.78 17.33 2.92 25.69a67.24 67.24 0 003.84 10.85 63.24 63.24 0 007.4 12.4 67.86 67.86 0 008.36 9 9.53 9.53 0 013.07 5.2c1.92 8.15 3.69 16.34 5.88 24.42a202 202 0 008 24.07 119.62 119.62 0 009.47 18.73 98.05 98.05 0 0013.84 17.09 99.52 99.52 0 0017.06 13.55 118.89 118.89 0 0018.52 9.32 193.35 193.35 0 0039.71 11 209.11 209.11 0 0075-.15 185 185 0 0019.89-4.49 121.33 121.33 0 0019.37-7.34c11.24-5.49 21.37-13 30.65-21.44 3.72-3.38 7.31-6.89 10.72-10.56 5.19-5.57 9.69-5.57 14.88 0 4.08 4.38 8.4 8.54 12.89 12.49a146.76 146.76 0 0015.3 11.9 110.4 110.4 0 0018.91 10.18 137.93 137.93 0 0021 6.71 212.27 212.27 0 0022.22 4.1 218.91 218.91 0 0051.1.65 214.71 214.71 0 0045.3-9.85 132.9 132.9 0 0020.47-8.56 103.31 103.31 0 0018.86-13.06 98.49 98.49 0 0015.74-17.15 111.09 111.09 0 0011.12-20 186 186 0 008.62-24.69c2.46-8.83 4.42-17.79 6.53-26.72a9.58 9.58 0 013.14-5.39z" />
      <path d="M778.9 351.52c-3.67 15.66-7.53 31.3-13.67 46.17a139.62 139.62 0 01-9.23 18.53 118.66 118.66 0 01-12.4 17 122 122 0 01-32.6 26.39 144.16 144.16 0 01-17.94 8.28 210.42 210.42 0 01-28.88 8.68 235.1 235.1 0 01-81.53 4.4 211.71 211.71 0 01-38.18-7.9 141.63 141.63 0 01-18.05-6.91 131 131 0 01-16.84-9.53 165.93 165.93 0 01-17.93-13.88c-4.83-4.27-8.61-4.27-13.45 0a149.83 149.83 0 01-33.39 22.74 156.32 156.32 0 01-33.94 11.45 236.13 236.13 0 01-24.22 4 241 241 0 01-38.27 1.48 236.9 236.9 0 01-53.8-8.73 182.45 182.45 0 01-26.43-9.47 131 131 0 01-16.38-8.91 121.4 121.4 0 01-15.84-12.18 118.74 118.74 0 01-13.57-14.52 122.17 122.17 0 01-10.72-16.3 150.1 150.1 0 01-8-17c-5.66-14.22-9.32-29.1-12.79-44a9.73 9.73 0 00-2.62-4.78 90.43 90.43 0 01-6.12-7 87.65 87.65 0 01-14.61-28.92c-2.44-8.6-3.29-17.56-3.85-26.51a225.72 225.72 0 01-.11-31.1 101.31 101.31 0 013-18.8 80 80 0 015.81-15.09 88.09 88.09 0 0111.84-17.7 78.56 78.56 0 018.89-8.89 84.45 84.45 0 0112.37-8.52 83.3 83.3 0 0113.07-6.24 105.94 105.94 0 0113.64-3.76 389.23 389.23 0 0139.43-6.29c15-1.75 30.06-3.15 45.15-3.82q21.43-.95 42.9 0a404.59 404.59 0 0145.29 4.57c16.44 2.65 32.66 6.44 49 9.72 9.51 1.9 19.06 3.63 28.37 6.27a9.65 9.65 0 005.43 0C504 180 520.48 176.81 537 173.67c16.32-3.09 32.64-6.1 49.12-8 11.17-1.28 22.42-2 33.67-2.42 9-.3 18.05-.35 27.06 0 16.6.61 33.14 2.48 49.67 4.36 13.69 1.56 27.37 3.13 40.79 6.2a107.85 107.85 0 0120 6.4 78.47 78.47 0 0114.7 8.68 85.78 85.78 0 0116.8 16.6 82.63 82.63 0 0111.13 19.16 85.5 85.5 0 015.22 18.92c1.55 9.61 1.66 19.39 1.46 29.16a183.69 183.69 0 01-2.5 29.52 87.66 87.66 0 01-22.66 44.56 9.74 9.74 0 00-2.56 4.71z" />
      <path d="M797.49 361.23c-2.7 11.31-5.65 22.57-9.45 33.56a179.62 179.62 0 01-9.1 21.84 143.56 143.56 0 01-20.59 30.59 140.78 140.78 0 01-27.9 24.28 152.78 152.78 0 01-20.1 11.16 210.93 210.93 0 01-42.13 13.81 263.88 263.88 0 01-31.62 5.23 252.64 252.64 0 01-33.69 1.11 269.21 269.21 0 01-33.29-3.19 220.9 220.9 0 01-31.31-7.21A151.47 151.47 0 01491 468.33c-4.4-3.28-7.74-3.28-12.14 0A161.89 161.89 0 01462 479.41a155.86 155.86 0 01-27.11 11.93 225.09 225.09 0 01-45.62 9.78 266.64 266.64 0 01-30.99 1.88 251.43 251.43 0 01-39.61-3.2 259.19 259.19 0 01-36.47-8.56 193.38 193.38 0 01-22.1-8.32 149.1 149.1 0 01-28.58-17 139 139 0 01-24.86-24.4A147.6 147.6 0 01189.22 413a190.06 190.06 0 01-8.05-20c-3.53-10.42-6.29-21.08-8.84-31.78a9.85 9.85 0 00-2.24-4.35q-2.67-3-5.08-6.27a104.25 104.25 0 01-11.85-20.15 109 109 0 01-7.16-22.74c-2.25-11.25-2.86-22.77-3.09-34.27-.22-10.71-.11-21.39 1.48-32A102.1 102.1 0 01163 195.77a99.64 99.64 0 0129.4-27.6 101.13 101.13 0 0117.5-8.84 131.71 131.71 0 0117.73-5.16 400.69 400.69 0 0147.1-7.37c16.79-1.78 33.61-3 50.5-3.54a426.54 426.54 0 0149.67 1.12c17.83 1.53 35.48 4.48 53.11 7.64 18.31 3.28 36.6 6.78 54.6 11.45a9.9 9.9 0 005.07 0c19.37-5 39-8.74 58.74-12.18 15.42-2.7 30.86-5.19 46.42-6.7a420.18 420.18 0 0164.15-1.1c19.85 1.11 39.61 3.43 59.33 6.16 11.58 1.61 23.15 3.35 34.36 6.62a109.37 109.37 0 0121.48 8.7 100 100 0 0118.77 13.18 107.21 107.21 0 0118.13 20.78 101.46 101.46 0 0111.62 23.21 113 113 0 015.39 24.64 207.67 207.67 0 01.72 31.15 185.56 185.56 0 01-3.25 30.91 106.21 106.21 0 01-8.48 25.16 108.31 108.31 0 01-15.38 23 9.86 9.86 0 00-2.19 4.23z" />
      <path d="M816.15 370.53c-3.64 14.77-7.81 29.47-13.67 43.47a178.26 178.26 0 01-10.63 21.4 161.28 161.28 0 01-42 47.23 166.29 166.29 0 01-19.62 12.74A185.1 185.1 0 01707.12 506a249.64 249.64 0 01-34.7 10.34 275.87 275.87 0 01-106.15 3.29 253.2 253.2 0 01-25.69-5.41 195.44 195.44 0 01-26-8.92 173.8 173.8 0 01-24.27-12.77c-3.94-2.49-6.93-2.49-10.87 0a169.15 169.15 0 01-22.51 12 193.78 193.78 0 01-27.63 9.6 260.9 260.9 0 01-37.39 7.12 281.15 281.15 0 01-54.65 1.15 274 274 0 01-60.57-11.65 197.38 197.38 0 01-41.12-17.7 162.58 162.58 0 01-23.36-16.68 158.13 158.13 0 01-20.94-21.57 164.11 164.11 0 01-15.76-23.89 189.66 189.66 0 01-9.23-19.63A296.86 296.86 0 01154 371.64a9.75 9.75 0 00-2-4 26.49 26.49 0 00-3.14-4.83A125.72 125.72 0 01135 339.43a128.24 128.24 0 01-8.64-26.43c-2.53-12.09-3.35-24.47-3.7-36.85a222.16 222.16 0 011.34-34.82 129.59 129.59 0 016.74-27.86 126.42 126.42 0 0113.91-26.82 119.93 119.93 0 0134.34-34 121.3 121.3 0 0120.29-10.93 144.29 144.29 0 0120.5-6.57 351.34 351.34 0 0143.7-7.54q25.21-3 50.56-4.24a471.43 471.43 0 0151.27-.09c18 1.08 35.89 3.61 53.71 6.51 21.4 3.48 42.71 7.5 63.74 12.73a9.83 9.83 0 004.88 0c17.1-4.21 34.4-7.56 51.74-10.65 16.25-2.89 32.55-5.55 49-7.24a421.92 421.92 0 0156.54-2c17.85.55 35.63 2.22 53.39 4.21 14.62 1.64 29.21 3.49 43.61 6.47a163.22 163.22 0 0126.65 7.56 118.23 118.23 0 0121 10.77A126 126 0 01845.21 237a186.21 186.21 0 012.12 28 273.25 273.25 0 01-1.14 28.61 162.09 162.09 0 01-4.92 28.34 121.1 121.1 0 01-10 25 130 130 0 01-13.16 19.78 9.85 9.85 0 00-1.96 3.8z" />
      <path d="M834.85 379.53c-2.69 10.57-5.7 21.07-9.38 31.34a215.94 215.94 0 01-9.74 23 189.55 189.55 0 01-11 19.24 180.14 180.14 0 01-16.27 21.36 183.91 183.91 0 01-19.4 18.77 181.15 181.15 0 01-17.7 13.12 199.6 199.6 0 01-37 18.59A281 281 0 01649 541a290 290 0 01-86-1.37 255.32 255.32 0 01-42.23-10.22 200.32 200.32 0 01-31.06-13.7c-3.51-1.92-6.25-1.92-9.76 0a197.83 197.83 0 01-26.19 11.9 249.48 249.48 0 01-49.48 12.46 306 306 0 01-76.79 1.76c-27.44-2.81-54.63-9.23-79.92-20.2a194.71 194.71 0 01-45.57-27.2 177.57 177.57 0 01-31.43-33.37 193.41 193.41 0 01-19.64-33.94c-6.87-15.24-11.82-31.33-15.94-47.56a9.76 9.76 0 00-1.77-3.65 146.12 146.12 0 01-12.61-19.65A149.85 149.85 0 01105.46 312c-1.12-6.44-1.78-13-2.28-19.47a304 304 0 01-.78-36.53 176.7 176.7 0 014.37-33.58A142.32 142.32 0 01118 191.79a147.57 147.57 0 0116.71-26 145.09 145.09 0 0119-19.55 143.21 143.21 0 0143.69-25.55 192.7 192.7 0 0127-7.44c14.78-3.08 29.74-5.1 44.74-6.71q24.63-2.66 49.41-3.71a474.5 474.5 0 0151.16.39c18.67 1.26 37.25 4 55.74 7.06 19.26 3.21 38.43 6.82 57.4 11.36a9.87 9.87 0 004.71 0c18.7-4.43 37.59-8 56.55-11.22 16.26-2.74 32.57-5.19 49-6.66a437.28 437.28 0 0153.81-1.4c18.39.63 36.71 2.34 55 4.42 14.83 1.69 29.63 3.62 44.24 6.65a188.07 188.07 0 0127.19 7.57 138.5 138.5 0 0123.06 11 140.91 140.91 0 0120.74 15.1 146.64 146.64 0 0113.55 13.5 149.82 149.82 0 0117.1 23.77 142 142 0 0114.79 36.16A164.09 164.09 0 01867 248a264.39 264.39 0 01.6 27.87 269.38 269.38 0 01-2.1 28.09 164.87 164.87 0 01-6.23 28.72A145.91 145.91 0 01836.59 376a9.85 9.85 0 00-1.74 3.53z" />
      <path d="M854.33 387.27c-4.28 14.6-8.77 29.16-14.67 43.16a215.41 215.41 0 01-17.9 33.84 202.88 202.88 0 01-59.36 59.17c-22.91 14.84-48.76 24.78-75.33 31.27a323.52 323.52 0 01-80.88 9.14 307.83 307.83 0 01-69.19-8.64 241.75 241.75 0 01-47.64-16.83 9.14 9.14 0 00-8.84 0q-9.57 4.62-19.52 8.34a268.29 268.29 0 01-53.52 13.51 327.45 327.45 0 01-81.86 1.86c-29.53-3-58.71-10-85.93-21.76a216.66 216.66 0 01-50.41-30.14A197.79 197.79 0 01154.14 473a212.92 212.92 0 01-21.73-37.5 301.08 301.08 0 01-16.14-47.09 9.78 9.78 0 00-1.58-3.36 169.26 169.26 0 01-29.17-69.11c-1.23-7-2-14.07-2.55-21.15a328.54 328.54 0 01-1-37.91 201.28 201.28 0 014.52-37.17 162.94 162.94 0 0112.3-34.91 166 166 0 0139.83-52.39 163.73 163.73 0 0147-28.92 204.4 204.4 0 0131.7-9.49A424.36 424.36 0 01262 86.87q24.87-2.82 49.84-4.08a511.55 511.55 0 0152.54-.26A560.52 560.52 0 01421.16 89c20.72 3.25 41.33 7.08 61.75 11.81a9.8 9.8 0 004.57 0c20.81-4.78 41.82-8.68 62.92-12 16.79-2.63 33.64-4.89 50.58-6a467.51 467.51 0 0155.27-.29c20.07 1 40.06 3 60 5.61a404.31 404.31 0 0141.19 7 189.81 189.81 0 0128.9 9.14 158.87 158.87 0 0125.39 13.37 161.91 161.91 0 0121.66 16.75 170.31 170.31 0 0134.86 45 164.32 164.32 0 019 20.21 162.07 162.07 0 015.83 19.4 190.18 190.18 0 014.31 30.13c.65 9.74.72 19.52.41 29.28a277.87 277.87 0 01-2.4 29.59 182.73 182.73 0 01-7.24 32 166.85 166.85 0 01-22.29 44 10 10 0 00-1.54 3.27z" />
      <path d="M872.3 397a322.59 322.59 0 01-13.92 41.36 234.27 234.27 0 01-19.62 37 222.39 222.39 0 01-65.35 65.12c-24.82 16-52.77 26.91-81.54 34a344 344 0 01-85.93 9.71 327.32 327.32 0 01-73.81-9.19A271.85 271.85 0 01489 560.55a9.22 9.22 0 00-8.1 0q-6.36 2.78-12.89 5.2a289.19 289.19 0 01-57.57 14.54 348 348 0 01-86.94 2c-31.59-3.23-62.8-10.7-91.94-23.33a236.87 236.87 0 01-55.15-33 218.51 218.51 0 01-38.68-41 233.62 233.62 0 01-23.81-41.09 314.83 314.83 0 01-16.33-46.77 9.68 9.68 0 00-1.48-3.1 190.77 190.77 0 01-11.19-18.35A185.73 185.73 0 0170.29 340a224.82 224.82 0 01-7.63-43.61 328 328 0 01-.91-40.27 227.24 227.24 0 015-40.92 182.31 182.31 0 0113.84-39.28 188.1 188.1 0 0120.74-33.38 185.25 185.25 0 0176.78-57.9 226 226 0 0134.8-10.51 440.76 440.76 0 0146.81-7.42q25.47-2.88 51.09-4.19a527.1 527.1 0 0154.65-.25 581.12 581.12 0 0158.85 6.64C444 72 463.57 75.57 483 80a9.83 9.83 0 004.45 0c21.28-4.76 42.74-8.68 64.31-11.93A553.51 553.51 0 01608 62a522.3 522.3 0 0161.93 1q31.09 2.15 61.9 6.78c14.1 2.12 28.16 4.65 41.84 8.62a189.65 189.65 0 0132.77 12.84 179.17 179.17 0 0128.35 17.88A185.57 185.57 0 01857 129.19a188.87 188.87 0 0121.34 27.37 185.1 185.1 0 0114.84 28 180 180 0 018.11 23.49 199.58 199.58 0 015.76 32.08 289 289 0 011.2 33.55 315.26 315.26 0 01-2.23 33.5 209 209 0 01-7.93 37.27 188.44 188.44 0 01-24.38 49.38 10.12 10.12 0 00-1.41 3.17z" />
      <path d="M891 405.49a340 340 0 01-13.93 40.73 254.09 254.09 0 01-21.35 40.25A243.16 243.16 0 01821.58 528a240.27 240.27 0 01-37.17 29.57c-26.73 17.25-56.78 29-87.73 36.61a363.61 363.61 0 01-91 10.29 347.67 347.67 0 01-78.41-9.78 301.52 301.52 0 01-38.57-12.24 9.32 9.32 0 00-7.5 0 288 288 0 01-32.78 10.76 343.06 343.06 0 01-69.36 10.66 367 367 0 01-88.77-5.87 332.29 332.29 0 01-46.21-12.31 288.79 288.79 0 01-36.26-15.37 247.22 247.22 0 01-53-36 239.29 239.29 0 01-37.26-42.61 255.58 255.58 0 01-23.5-42.35 333.36 333.36 0 01-15.25-43.66 9.61 9.61 0 00-1.33-2.89q-5.28-8-9.84-16.35a206.57 206.57 0 01-15.34-35.69 230.72 230.72 0 01-9-43.83 338.93 338.93 0 01-2-40.91 277.55 277.55 0 013-41.34A206.07 206.07 0 01164.68 68.23a210.33 210.33 0 0120-7.54 295.92 295.92 0 0139.17-9.46c15.45-2.74 31-4.61 46.65-6.12 16.58-1.6 33.19-2.8 49.83-3.39a519 519 0 0153.94.7c19 1.32 38 3.93 56.83 7 17.42 2.81 34.77 6 52 9.76a9.93 9.93 0 004.34 0c20.31-4.43 40.76-8.13 61.32-11.23a569.07 569.07 0 0158.24-6.3 544 544 0 0164.35 1q31.9 2.19 63.53 7c15 2.27 29.93 5 44.47 9.21A209 209 0 01815.65 73a200.4 200.4 0 0131.53 20 205.42 205.42 0 0124.65 22.33 209.8 209.8 0 0123.64 30.29 206.73 206.73 0 0116.45 31.1 201.14 201.14 0 019 26.14 219.72 219.72 0 016.36 35.36 309.36 309.36 0 011.3 35.89 334.83 334.83 0 01-2.38 35.66 227.9 227.9 0 01-8.75 40.91 208.79 208.79 0 01-25.11 52 10 10 0 00-1.34 2.81z" />
      <path d="M909.78 414a353.86 353.86 0 01-13.94 40.17 274.86 274.86 0 01-23.08 43.47 263.15 263.15 0 01-37 45 259.83 259.83 0 01-40.34 32.07c-28.63 18.45-60.77 31.15-93.91 39.27a384.52 384.52 0 01-96 10.87 367.93 367.93 0 01-83-10.37 326.72 326.72 0 01-33.94-10.3 9.41 9.41 0 00-7 0 317.34 317.34 0 01-36.87 11 373.79 373.79 0 01-87.87 9.62 391.73 391.73 0 01-53-3.91 360.14 360.14 0 01-35.85-7 333.79 333.79 0 01-50.14-16.57 281.54 281.54 0 01-56.24-31.53 258.39 258.39 0 01-51.73-50.28 273 273 0 01-31.68-52.17c-3.84-8.25-7.28-16.69-10.36-25.26-2.83-7.89-5.35-15.89-7.67-23.94a9.82 9.82 0 00-1.22-2.7q-5.31-8.24-9.92-16.87a225.48 225.48 0 01-17.23-41.63 261.83 261.83 0 01-9.22-49.26A362.43 362.43 0 0121 258.35a278.65 278.65 0 015-46.81 222.82 222.82 0 0115-46.8 226.32 226.32 0 01125-119 255 255 0 0132.41-10 436.54 436.54 0 0147.07-8.32c18.33-2.35 36.74-4 55.19-5.11 18.86-1.12 37.75-1.67 56.63-1.11q15 .45 29.87 1.84c10.27 1 20.5 2.28 30.71 3.74 21.9 3.13 43.7 6.93 65.33 11.54a9.78 9.78 0 004.24 0q29-6.16 58.33-10.54c20-3 40.11-5.45 60.3-6.46a565.4 565.4 0 0166.76 1q32.73 2.25 65.17 7.13c15.77 2.51 31.58 5.39 47 9.85A228.47 228.47 0 01824.82 55a221 221 0 0134.74 21.9 226.38 226.38 0 0127.08 24.52 230.63 230.63 0 0125.92 33.23 226.89 226.89 0 0118.06 34.15 219.48 219.48 0 019.94 28.79 238.74 238.74 0 017 38.63 327.67 327.67 0 011.4 38.24 349.46 349.46 0 01-2.54 37.84 249.35 249.35 0 01-9.56 44.55A229.76 229.76 0 01911 411.23a9.8 9.8 0 00-1.22 2.77z" />
      <path d="M928.51 422.31a365.12 365.12 0 01-14 39.7 295.44 295.44 0 01-24.79 46.68 284 284 0 01-39.87 48.43 279.48 279.48 0 01-43.52 34.57c-30.53 19.66-64.76 33.31-100.08 41.92a403.1 403.1 0 01-101.1 11.45 386.88 386.88 0 01-87.63-10.94q-14.79-3.64-29.28-8.56a9.46 9.46 0 00-6.55 0 355.59 355.59 0 01-54.93 13.82A405.61 405.61 0 01329 644a399.29 399.29 0 01-56.34-8.11 357.4 357.4 0 01-62.33-19.64 311.75 311.75 0 01-38.52-19.37A282.92 282.92 0 01135 570.75a279 279 0 01-34.55-34.53 286.93 286.93 0 01-24.84-34.65 305.86 305.86 0 01-24.3-49.48c-3.74-9.72-7-19.62-9.94-29.61a9.67 9.67 0 00-1.14-2.48q-5.43-8.64-10.16-17.69A244.54 244.54 0 0111 354.14a292.57 292.57 0 01-9.2-54.83 376.07 376.07 0 01-.8-50.24 281.84 281.84 0 017.6-52.89 242.5 242.5 0 0119.55-51.76A246.58 246.58 0 01139.27 35.23 249.28 249.28 0 01174 21.41a342.52 342.52 0 0143.67-10.35c18.29-3.2 36.72-5.36 55.21-7 20-1.78 40-3 60-3.27 14.72-.24 29.52 0 44.25 1.07 13.67 1 27.28 2.57 40.85 4.45q32.77 4.56 65.18 11.28a10 10 0 004.15 0q27.54-5.73 55.34-9.87C563.33 4.61 584.12 2.1 605 1.06a587.09 587.09 0 0169.18 1.06q33.53 2.3 66.82 7.31c16.74 2.55 33.43 5.65 49.69 10.36a248.92 248.92 0 0143.35 17A241.35 241.35 0 01872 60.7a246.57 246.57 0 0129.5 26.72 250.22 250.22 0 0147.89 73.36 239.22 239.22 0 0110.85 31.44 260 260 0 017.56 41.9 352.55 352.55 0 011.5 40.59 369 369 0 01-2.69 40A269.93 269.93 0 01956.17 363a251.15 251.15 0 01-26.52 56.82 10.42 10.42 0 00-1.14 2.49z" />
    </g>
  </svg>
  )
}

export default LoaderSVG
