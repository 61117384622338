import { useEffect } from 'react'
import LifestyleGallery from '~/components/LifestyleGallery'
import useUiStore from '~/store/ui'
import previewFriends from 'IMAGE_LifestyleFriends_Preview'
import previewMotors from 'IMAGE_LifestyleMotors_Preview'
import previewTrain from 'IMAGE_LifestyleTrain_Preview'

const LifestyleCapture = () => {
  const setAdaContent = useUiStore(state => state.setAdaContent)

  const vidSrcKeys = ['lifestyle-friends','lifestyle-motors','lifestyle-train']
  const analyticsKeys = ['Capture-5: Lifestyle-friends','Capture-6: Lifestyle-motors','Capture-7: Lifestyle-train']
  const imgSrcs = [previewFriends, previewMotors, previewTrain]
  
  useEffect(() => {
    setAdaContent([{el:'h1',key:'features.capture.tag'}])
  }, [])

  return(
    <div id="LifestyleCapture">
      <LifestyleGallery
        vidSrcKeys={vidSrcKeys}
        analyticsKeys={analyticsKeys}
        rootPath={'/features/capture/gallery'}
        imgSrcs={imgSrcs}
        template={`
          "a" 33.3%
          "b" 33.4%
          "c" 33.3%
        `}
      >
      </LifestyleGallery>
    </div>
  )
}

export default LifestyleCapture