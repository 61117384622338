import '~/styles/LanguageToggle.scss'
import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useUiStore from '~/store/ui'

const LangaugeToggle = () => {
  const { i18n } = useTranslation()
  const {
    hideUi,
    setChangingLanguage,
    frenchDialect,
    setLanguageToggleOpen: setOpen,
    languageToggleOpen: open,
  } = useUiStore(state => state)
  const closeTmt = useRef()
  const availableLangs = {
    'en': 'EN',
    'es': 'ES',
    [frenchDialect]: 'FR',
    'it': 'IT',
  }

  useEffect(() => {
    if (open) { closeTmt.current = setTimeout(() => setOpen(false), 4000) }
    else { clearTimeout(closeTmt.current) }
    return () => {
      clearTimeout(closeTmt.current)
    }
  }, [open])

  function changeLang(to) {
    setChangingLanguage(true)
    .then(() => {
      i18n.changeLanguage(to)
      setOpen(false)
      return
    })
    .then(() => {
      setChangingLanguage(false)
    })
  }

  return(
    <div
      id="LanguageToggle"
      style={{
        opacity: hideUi ? 0 : 1,
        transition: `opacity 0.33s ease`
      }}
    >
      <div
        className={`options${open ? ' open' : ''}`}
        style={{
          height: open
            ? `${(Object.keys(availableLangs).length + 2) * 1.25}em`
            : '100%'
        }}>
        {
          Object.keys(availableLangs).sort().map((l,i,a) => {
            const invIdx = (a.length - 1 - i)
            const pctOffset = -100 * invIdx
            const emOffset = invIdx * 0.5
            const gate = open ? 1 : 0
            return(
              <button
                key={l}
                onClick={() => hideUi ? null : open ? changeLang(l) : setOpen(true)}
                style={{
                  opacity: open || i18n.language === l ? 1 : 0,
                  transform: `translateY(calc(${gate} * (${pctOffset}% - ${emOffset}em)))`,
                }}
              >
                {availableLangs[l]}
              </button>
            )
          })
        }
      </div>
    </div>
  )
}

export default LangaugeToggle