import '~/styles/FeatureListen.scss'
import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import gsap from 'gsap'
import FeatureProgressBar from '~/components/FeatureProgressBar'
import FeatureSequence from '~/components/FeatureSequence'
import useUiStore from '~/store/ui'
import useVideoStore from '~/store/video'
import { splitLines } from '~/utils/splitText'
import { multiLine } from '~/utils/anims'
import { enableSequenceControls } from '~/config'
import useSequenceEvents from '~/hooks/useSequenceEvents'

const SEGMENTS = [
  { name: 'Listen-1: Listen', start: 1, end: 5 },
  { name: 'Listen-2: Playback and Volume', start: 7.5, end: 23 },
  { name: 'Listen-3: Music and Podcast', start: 26, end: 31 },
]

const FeatureListen = () => {
  const { transDest, setAdaContent } = useUiStore(state => state)
  const [mountedSequence, setMountedSequence] = useState(false)
  const bgVid = useVideoStore(state => state.vidRef)
  const cnr = useRef()
  const progressRef = useRef()
  const tlRef = useRef()
  const c1 = useRef()
  const c1t = useRef()
  const c1d = useRef()
  const c2 = useRef()
  const c2t = useRef()
  const c2d = useRef()
  const c3 = useRef()
  const c3t = useRef()
  const c3d = useRef()
  const safeToUnmount = useRef(false)
  const { t } = useTranslation()
  const { teardown, initSequence, play, pause, back, next } = useSequenceEvents(
    tlRef,
    bgVid,
    SEGMENTS,
    initTl,
    {
      endDest: '/features/listen/gallery',
      vidSrcKey: 'listen',
    }
  )

  useEffect(() => {
    setAdaContent([{el:'h1',key:'features.listen.c1.title'}])
    return handleUnmount
  }, [])

  useEffect(() => {
    if (transDest) {
      teardown()
      setAdaContent([])
      safeToUnmount.current = true
    }
  }, [transDest])

  function handleUnmount() {
    if (!safeToUnmount.current) {
      teardown()
      setAdaContent([])
      safeToUnmount.current = true
    }
  }

  function initTl() {
    tlRef.current = gsap.timeline({ paused: true })
    const tl = tlRef.current
    const c1Title = c1t.current
    const c1Desc = splitLines(c1d.current)
    const c3Title = c3t.current
    const c3Desc = splitLines(c3d.current)
    const c2tip1 = splitLines(c2.current.querySelector('.tip1'))
    const c2tip2 = splitLines(c2.current.querySelector('.tip2'))
    const c2tip3 = splitLines(c2.current.querySelector('.tip3'))
    
    gsap.set(progressRef.current, { scaleX: 0 })
    
    tl.add(() => setAdaContent([{el:'h1',key:'features.listen.c1.title'},{el:'p',key:'features.listen.c1.description'}]), SEGMENTS[0].start)
    tl.fromTo(c1Title, { opacity: 0 }, { duration: 0.5, opacity: 1 }, SEGMENTS[0].start)
    tl.fromTo(c1Desc, multiLine.from, multiLine.to)
    tl.to(progressRef.current, { ease: "none", duration: SEGMENTS[0].end-SEGMENTS[0].start, scaleX: 0.333 }, SEGMENTS[0].start)
    tl.addLabel("c1:in")
    tl.to(c1.current, { duration: 0.5, opacity: 0 }, SEGMENTS[0].end)
    tl.addLabel("c1:out")
    tl.add(() => setAdaContent([{el:'h1',key:'features.listen.c2.title'},{el:'p',key:'features.listen.c2.tip1'}]), SEGMENTS[1].start)
    tl.fromTo(c2t.current, { opacity: 0 }, { duration: 0.5, opacity: 1 }, SEGMENTS[1].start)
    tl.fromTo(c2tip1, multiLine.from, multiLine.to)
    tl.to(c2tip1, multiLine.out, "+=2.5")
    tl.add(() => setAdaContent([{el:'h1',key:'features.listen.c2.title'},{el:'p',key:'features.listen.c2.tip2'}]))
    tl.fromTo(c2tip2, multiLine.from, multiLine.to)
    tl.to(c2tip2, multiLine.out, "+=5")
    tl.add(() => setAdaContent([{el:'h1',key:'features.listen.c2.title'},{el:'p',key:'features.listen.c2.tip3'}]))
    tl.fromTo(c2tip3, multiLine.from, multiLine.to)
    tl.to(progressRef.current, { ease: "none", duration: SEGMENTS[1].end-SEGMENTS[1].start, scaleX: 0.666 }, SEGMENTS[1].start)
    tl.addLabel("c2:in")
    tl.to(c2.current, { duration: 0.5, opacity: 0 }, SEGMENTS[1].end)
    tl.addLabel("c2:out")
    tl.add(() => setAdaContent([{el:'h1',key:'features.listen.c3.title'},{el:'p',key:'features.listen.c3.description'}]), SEGMENTS[2].start)
    tl.fromTo(c3Title, { opacity: 0 }, { duration: 0.5, opacity: 1 }, SEGMENTS[2].start)
    tl.fromTo(c3Desc, multiLine.from, multiLine.to, '-=0.33')
    tl.to(progressRef.current, { ease: "none", duration: SEGMENTS[2].end-SEGMENTS[2].start, scaleX: 1 }, SEGMENTS[2].start)
    tl.addLabel("c3:in")
    tl.to(c3Title, { duration: 0.5, opacity: 0 }, SEGMENTS[2].end)
    tl.to(c3Desc, multiLine.out, SEGMENTS[2].end)
    tl.addLabel("c3:out")
  }

  function init() {
    initSequence()
    setMountedSequence(true)

    return(() => {
      tlRef.current.kill()
      setMountedSequence(false)
    })
  }

  return(
    <>
      <FeatureSequence action={init} play={play} pause={pause} back={back} next={next} control={enableSequenceControls}>
        <div id="FeatureListen" ref={cnr} style={{opacity: mountedSequence ? 1 : 0}}>
          <div className="content-overlay c1" ref={c1}>
            <div className="g1">
              <h1 className="title" ref={c1t}>{t('features.listen.c1.title')}</h1>
              <p className="description" ref={c1d}>{t('features.listen.c1.description')}</p>
            </div>
          </div>
          <div className="content-overlay c2" ref={c2}>
            <div className="g1">
              <h2 className="title" ref={c2t}>{t('features.listen.c2.title')}</h2>
              <div className="description tips" ref={c2d}>
                <p className="tip1">{t('features.listen.c2.tip1')}</p>
                <p className="tip2">{t('features.listen.c2.tip2')}</p>
                <p className="tip3">{t('features.listen.c2.tip3')}</p>
              </div>
            </div>
          </div>
          <div className="content-overlay c3" ref={c3}>
            <div className="g1">
              <h2 className="title" ref={c3t}>{t('features.listen.c3.title')}</h2>
              <p className="description" ref={c3d}>{t('features.listen.c3.description')}</p>
            </div>
          </div>
        </div>
      </FeatureSequence>
      <FeatureProgressBar numSegments={3} ref={progressRef} />
    </>
  )
}

export default FeatureListen