import { useEffect, forwardRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { mobileQuery } from '~/config'
import useBsStore from '~/store/bs'
import useUiStore from '~/store/ui'
import { forceBsUi } from '~/config' 

const FeatureProgressBar = forwardRef((props, ref) => {
  const isMobile = useMediaQuery({ query: mobileQuery, options: { noSsr: true } })
  const isBrightSign = useBsStore(state => state.isBrightSign)
  const useBsUi = isBrightSign || forceBsUi
  const appBounds = useUiStore(state => state.appBounds)
  const defaultThickness = isMobile ? 6 : 10 + Math.max(0, appBounds.h - 1920) * .005
  const {
    height = defaultThickness,
    gapSize = defaultThickness,
    numSegments = 5
  } = props
  const pathsArray = new Array(numSegments).fill()
  const [width, setWidth] = useState(0)
  const [right, setRight] = useState(0)
  const [bottom, setBottom] = useState(0)

  useEffect(() => {
    resize()
  }, [appBounds, isMobile])
  
  function resize() {
    if (useBsUi) {
      let navDims = document.getElementById('BSToggles').getBoundingClientRect()
      setWidth(appBounds.w - navDims.right - navDims.left * 2)
      setRight(navDims.left)
      setBottom(appBounds.h - navDims.bottom + navDims.height/2 - height)
    } else if (isMobile) {
      let navDims = document.getElementById('FeaturesNavMobile').getBoundingClientRect()
      setWidth(appBounds.w - navDims.left * 2)
      setRight(navDims.left)
      setBottom(appBounds.h - navDims.top + navDims.left)
    } else {
      let gap = appBounds.w * 0.05
      setWidth(appBounds.w - gap*2)
      setRight(gap)
      setBottom(gap)
    }
  }

  function dimsAtIndex(i) {
    const segmentLength = (width - gapSize * numSegments) / numSegments
    const start = (segmentLength + gapSize) * i + gapSize / 2.5
    const end = start + segmentLength
    return { start, end }
  }

  return(
    <div
      className={`FeatureProgressBar`}
      style={{
        position: 'absolute',
        right: `${right}px`,
        bottom: `${bottom}px`,
        opacity: width ? 1 : 0,
        transition: `opacity 0.5s ease`,
      }}
    >
      <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
        <defs>
          <mask id="progress-bar-mask">
            <rect x="0" y="0" width={width} height={height} fill="#000" />
            {
              pathsArray.map((_,i) => {
                const dims = dimsAtIndex(i)
                return(
                  <path key={i} d={`M${dims.start} ${height/2} L${dims.end} ${height/2}`} strokeWidth={height/2} stroke="#FFF" strokeLinecap="round" />
                )
              })
            }
          </mask>
        </defs>
        <g mask="url(#progress-bar-mask)">
          <rect x="0" y="0" width={width} height={height} fill="rgba(255,255,255,0.25)" />
          <rect
            ref={ref}
            transform-origin={`${0} 0`}
            transform={`scale(0 1)`}
            x="0" y="0" width={width} height={height}
            fill="#FFF" />
        </g>
      </svg>
    </div>
  )
})

export default FeatureProgressBar